import React from 'react'
import PageTitle from '../../../PageTitle'
import CustomNumberEnterText from './CustomNumberEnterText'
import CustomNumberSearch from './CustomNumberSearch'
import {
    Switch,
    Route
} from 'react-router-dom'

/**
 * The custom number pick-a-number page that allows the user to search for numbers containing a string of text
 */
const CustomNumber = () : JSX.Element => {
    return (
        <>
            <PageTitle remoteConfigID="signup_custom_numbers_title" />

            <Switch>
                <Route
                    path="/pick-a-number/custom/search/:text"
                    render={({ match }) => <CustomNumberSearch text={match.params.text} />}>
                </Route>
                <Route path="/pick-a-number/custom">
                    <CustomNumberEnterText />
                </Route>
            </Switch>
        </>
    )
}

export default CustomNumber
