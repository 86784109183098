import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import { Color as AlertColorMUI } from 'alert-mui/src'

const ErrorIcon = () => (
    <svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ErrorOutlineIcon"><path d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"></path></svg>
)

const iconByType = {
    error: <ErrorIcon />
}

const useStyles = makeStyles(() =>
    createStyles({
        alert: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            margin: '0.5em 0',
            padding: '0.75em',
            fontWeight: 600,
            fontSize: '13px',
            lineHeight: '18px',
            '& svg': {
                width: '18.3px',
                height: '18.3px'
            }
        },
        error: {
            color: '#E85646',
            '& svg': {
                fill: '#E85646',
                marginBottom: '2px'
            }
        },
        info: {
            color: 'rgb(1, 67, 97)',
            '& svg': {
                fill: '#0288d1',
                marginBottom: '2px'
            },
            backgroundColor: 'rgb(229, 246, 253)'
        }
    })
)

interface AlertProps {
    showIcon: boolean,
    color: string,
    content: React.ReactNode
}

/**
 * Custom alert-like component that is advised for use throughout sign up.
 */
const Alert = ({ showIcon, color, content, ...props } : AlertProps) => {
    const classes = useStyles()

    return (
        <div className={`${classes.alert} ${classes[color]}`} {...props}>
            {showIcon &&
                <>{iconByType[color]} &nbsp;</>
            }
            {content}
        </div>
    )
}

/**
 *
 */
export const Color = AlertColorMUI

export default Alert
