import React, { useEffect, useState } from 'react'
import * as API from '../../../util/API'
import * as GTM from '../../../util/GTM'
import Alert, { Color as AlertColor } from '../../../design-components/Alert'
import NumberSearchResults from '../NumberSearchResults'
import Button from 'button'
import { mergePhoneNumbers } from '../../../util/PhoneNumberHelpers'
import Typography from 'typography'
import { PhoneNumberData } from '../../../types'
import { useHistory } from 'react-router-dom'

interface CustomNumberSearcProps {
    text: string
}

/**
 * The custom number pick-a-number page that allows the user to search for numbers containing a string of text
 */
const CustomNumberSearch = ({ text } : CustomNumberSearcProps) : JSX.Element => {
    const history = useHistory()

    const [loading, setLoading] = useState(true)
    const [numbers, setNumbers] = useState<PhoneNumberData[]>([])
    const [error, setError] = useState(null)

    const onSearch = (loadMore = false) => {
        setLoading(true)
        GTM.customSearch(text)
        return API.getNumbersContainingText(text).then(response => {
            const updateNumbers = mergePhoneNumbers(loadMore && numbers ? numbers : [], response?.data.numbers)
            setNumbers(updateNumbers)
            setError(null)
        }, error => setError(error)).then(() => setLoading(false))
    }

    useEffect(() => {
        onSearch()
    }, [text])

    const clearSearch = () => {
        history.push('/pick-a-number/custom')
    }

    return (
        <>
            {error && (
                <Alert
                    showIcon={true}
                    color={AlertColor.ERROR}
                    content={<>An error occured while trying to load search results, <a onClick={onSearch}>Click here to try again</a>
                    </>}
                />
            )}

            <NumberSearchResults
                numbers={numbers}
                zeroNumbersMessage={(
                    <>
                        <Typography variant="body1" paragraph={true} remoteConfigID="signup_custom_numbers_no_numbers" />

                        <Button onClick={clearSearch} color={'secondary'} size="small">
                            <Typography variant="buttonMedium" remoteConfigID="signup_custom_numbers_search_again" />
                        </Button>
                    </>
                )}
                footer={
                    <Typography variant="caption" color="textSecondary" align="center" remoteConfigID="signup_custom_numbers_footer" />
                }
                loading={loading}
                loadMoreNumbers={() => onSearch(true)}
            />
        </>
    )
}

export default CustomNumberSearch
