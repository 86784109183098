import React, { useState, useEffect } from 'react'
import PageTitle from '../../../PageTitle'
import {
    Switch,
    Route
} from 'react-router-dom'
import ShowLocalNumbers from './ShowLocalNumbers'
import ChooseAreaCode from './ChooseAreaCode'
import * as API from '../../../util/API'

/**
 * The local number pick-a-number page that:
 *   1. searches for numbers close to where the user is located initially,
 *   2. allows the user to search by entering a specific area code instead
 */
const LocalNumber = () : JSX.Element => {
    const [areaCodeOptions, setAreaCodeOptions] = useState<string[]>([])

    useEffect(() => {
        API.getAreaCodeList().then(areaCodes => setAreaCodeOptions(areaCodes))
    }, [])

    return (
        <>
            <PageTitle remoteConfigID="signup_local_numbers_title" />

            <Switch>
                <Route path="/pick-a-number/local/choose-area-code">
                    <ChooseAreaCode areaCodeOptions={areaCodeOptions} />
                </Route>
                <Route path="/pick-a-number/local">
                    <ShowLocalNumbers />
                </Route>
            </Switch>
        </>
    )
}

export default LocalNumber
