import React, { useContext } from 'react'
import TextField from '../../design-components/TextField'
import StateSelector from '../../design-components/StateSelector'
import ZipCodeTextField from '../../design-components/ZipCodeTextField'
import Select from 'select'
import { Grid } from '@material-ui/core'
import Typography from 'typography'
import { LockIcon } from 'svg-icons/src'
import Heading from '../../Heading'
import { createStyles, makeStyles } from '@material-ui/core'
import CreditCardForm from './CreditCardForm'
import LeadContext from '../../LeadContext'

const useStyles = makeStyles(() =>
    createStyles({
        requiredFieldsText: {
            color: '#197F9F !important',
            marginBottom: '15px'
        },
        footerText: {
            marginTop: '10px'
        },
        select: {
            height: '44px'
        },
        lockIcon: {
            width: '18px',
            height: '18px',
            marginBottom: '4px'
        },
        zipHelperText: {
            marginTop: '7px'
        }
    })
)

const fieldLabels = {
    street: 'Billing address',
    city: 'City',
    state: 'State',
    billing_zip_code: 'Postal code', // eslint-disable-line @typescript-eslint/naming-convention
    billing_country_code: 'Country' // eslint-disable-line @typescript-eslint/naming-convention
}

const countryOptions = [
    { value: 'US', label: 'United States' },
    { value: 'CA', label: 'Canada' }
]

/**
 * Form to collect business information as part of the sign-up checkout process
 */
const SecureCheckoutForm = ({ skipCreditCardEntry } : { skipCreditCardEntry?: boolean }) : JSX.Element => {
    const classes = useStyles()

    const { leadParams, setLeadParams, checkingZip, isZipValid, savingLead } = useContext(LeadContext)

    const disabled = savingLead

    const renderField = (
        field: string,
        fieldType: string,
        onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | undefined
    ) : JSX.Element => {
        const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
                onChange(event)
            } else {
                setLeadParams({ ...leadParams, [field]: event.target.value })
            }
        }

        const onXClick = () => {
            setLeadParams({ ...leadParams, [field]: '' })
        }

        return (
            <TextField
                data-testid={field}
                name={field}
                type={fieldType}
                label={fieldLabels[field]}
                InputProps={{
                    name: field // may serve as a hint to browser auto-fill logic as to what the field does
                }}
                value={leadParams[field] || ''}
                onChange={onFieldChange}
                disabled={disabled}
                onXClick={onXClick}
                fullWidth/>
        )
    }

    return (
        <>
            <Heading>
                Secure checkout <LockIcon className={classes.lockIcon} />
            </Heading>

            <br />

            <Grid container spacing={3}>
                {!skipCreditCardEntry &&
                    <Grid item xs={12}>
                        <CreditCardForm disabled={disabled} />
                    </Grid>
                }

                <Grid item xs={12} md={12}>
                    {renderField('street', 'text')}
                </Grid>

                <Grid item xs={12} md={6}>
                    <Select
                        className={classes.select}
                        name="country"
                        value={leadParams.billing_country_code || ''}
                        options={countryOptions}
                        data-testid="billing_country_code"
                        disabled={disabled}
                        label = 'Country'
                        onChange={(event) => {
                            const opt = countryOptions.find(opt => opt.value === event.target.value)

                            if (!opt) {
                                throw new Error('invalid option')
                            }

                            setLeadParams({
                                ...leadParams,
                                country: opt.label,
                                state: '', // Reset this as user will have to pick again
                                billing_zip_code: '', // eslint-disable-line @typescript-eslint/naming-convention
                                billing_country_code: opt.value // eslint-disable-line @typescript-eslint/naming-convention
                            })
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <StateSelector
                        className={classes.select}
                        data-testid="state"
                        countryCode={leadParams.billing_country_code || 'US'}
                        value={leadParams.state || ''}
                        onChange={val => {
                            setLeadParams({
                                ...leadParams,
                                state: val
                            })
                        }}
                        disabled={disabled} />
                </Grid>

                <Grid item xs={12} md={6}>
                    {renderField('city', 'text')}
                </Grid>

                <Grid item xs={12} md={6}>
                    <ZipCodeTextField
                        countryCode={leadParams.billing_country_code || 'US'}
                        value={leadParams.billing_zip_code || ''}
                        onChange={val => {
                            setLeadParams({
                                ...leadParams,
                                billing_zip_code: val // eslint-disable-line @typescript-eslint/naming-convention
                            })
                        }}
                        fullWidth={true}
                        disabled={disabled}
                        error={!checkingZip && !isZipValid}
                    />
                    {checkingZip
                        ? (
                            <Typography variant="helperText" align="left" display="block" className={classes.zipHelperText}>
                            Checking...
                            </Typography>
                        )
                        : !isZipValid
                            ? (
                                <Typography variant="helperText" align="left" display="block" color="#E85646" className={classes.zipHelperText}>
                            This entry is invalid
                                </Typography>
                            )
                            : null}
                </Grid>
            </Grid>
        </>
    )
}

export default SecureCheckoutForm
