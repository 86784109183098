import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * @param {SvgIconProps} props - svg props
 */
export const Location = (props: SvgIconProps): JSX.Element => {
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <path fillRule="evenodd" clipRule="evenodd" d="M24.1546 15.922C25.0889 14.4736 25.625 12.7842 25.625 10.9802C25.625 5.60636 20.868 1.25 15 1.25C9.13197 1.25 4.375 5.60636 4.375 10.9802C4.375 12.7842 4.91111 14.4736 5.84538 15.922C5.89055 16.0091 5.94304 16.0951 6.00313 16.1795L13.5569 26.7842C14.2367 27.7386 15.7633 27.7386 16.4431 26.7842L23.9969 16.1795C24.057 16.0951 24.1094 16.0091 24.1546 15.922ZM15 16.25C17.7614 16.25 20 14.0114 20 11.25C20 8.48858 17.7614 6.25 15 6.25C12.2386 6.25 10 8.48858 10 11.25C10 14.0114 12.2386 16.25 15 16.25Z" fill="white"/>
        </SvgIcon>
    )
}

export default Location
