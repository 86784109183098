import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import Typography from 'typography'

const useStyles = makeStyles(() =>
    createStyles({
        billingItem: {
            width: '100%',
            display: 'flex',
            justifyContent: 'stretch'
        },
        label: {
            flexGrow: 1
        },
        cost: {
            textAlign: 'right'
        }
    })
)

interface ItemProps {
    label: string,
    cost: string,
    unit?: string
}

/**
 * An individual item on the billing summary, display as a row
 */
const Item = ({ label, cost, unit = '$', ...otherProps } : ItemProps) : JSX.Element => {
    const classes = useStyles()

    const formatCost = (cost : string) : string => {
        if (isNaN(cost)) {
            return cost // the billing item amounts can be strings like "Free", "Included", etc.
        } else {
            return `${unit}${parseFloat(cost).toFixed(2)}`
        }
    }

    return (
        <div className={classes.billingItem} {...otherProps}>
            <Typography variant="subtitle3" color="textPrimary" align="left" className={classes.label} noWrap>
                {label}
            </Typography>

            <Typography data-testid="cost" variant="caption" color="textPrimary" align="right" className={classes.cost}>
                {formatCost(cost)}
            </Typography>
        </div>
    )
}

export default Item
