import React from 'react'
import NumberOptionTile from './NumberOptionTile'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Link from '../../design-components/Link'
import PhoneWithDialpadIcon from 'svg-icons/src/communication/PhoneWithDialpadIcon'
import LocationIcon from 'svg-icons/src/geography/LocationIcon'
import ABCLogo from 'svg-icons/src/logos/ABCLogo'
import PageTitle from '../../PageTitle'
import { ChevronForwardIcon } from 'svg-icons/src'
import Typography from 'typography'
import * as GTM from '../../util/GTM'
import { useMediaQuery, useTheme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => createStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        margin: '25px 0px',
        padding: '4px',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row',
            maxWidth: 'none'
        }
    },
    description: {
        textAlign: 'center',
        maxWidth: '537px',
        margin: '0 auto'
    },
    footer: {
        marginTop: '0px',
        [theme.breakpoints.up('md')]: {
            marginTop: '40px',
            maxWidth: 'none',
            flexWrap: 'nowrap'
        },
        color: '#364047',
        '& svg': {
            width: '18px',
            height: '18px'
        }
    },
    footerLink: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'inline'
        }
    }
}))

/**
 * Pick a number step of sign-up
 */
const PickANumber = () : JSX.Element => {
    const classes = useStyles()
    const history = useHistory()

    const theme = useTheme()
    const isMobile = !useMediaQuery(theme.breakpoints.up('md'))

    const numberOptions = [
        { id: 'local', remoteConfigID: 'signup_number_options_local', img: 'local.png', icon: LocationIcon },
        { id: 'toll-free', remoteConfigID: 'signup_number_options_toll_free', img: '800_numbers.png', icon: PhoneWithDialpadIcon },
        { id: 'custom', remoteConfigID: 'signup_number_options_custom', img: 'vanity-icon-2.svg', icon: ABCLogo }
    ]

    return (
        <>
            <PageTitle remoteConfigID="signup_number_options_title" />

            <Typography remoteConfigID="signup_number_options_description" variant="body2" align="center" color="textPrimary" className={classes.description} />

            <div className={classes.container}>
                {numberOptions.map((opt, idx) => (
                    <NumberOptionTile
                        key={opt.id}
                        id={opt.id}
                        renderIcon={props => <opt.icon {...props} />}
                        remoteConfigID={opt.remoteConfigID}
                        img={`/number-option-tiles/${isMobile ? 'mobile' : 'desktop'}/${idx + 1}.png`}
                        onClick={() => {
                            history.push(`/pick-a-number/${opt.id}`)
                            GTM.pickNumberType(opt.id)
                        }}
                    />
                ))}
            </div>

            <Typography variant="body2" align="center" className={classes.footer}>
                Already have a number you love? <Link className={classes.footerLink} to="/pick-a-number/transfer">Transfer your number to phone.com<ChevronForwardIcon /></Link>
            </Typography>
        </>
    )
}

export default PickANumber
