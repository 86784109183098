import React, { useEffect } from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import PageTitle from '../../PageTitle'
import { CheckAltIcon } from 'svg-icons/src'
import { AccountDetails } from '../../types'
import Typography from 'typography'
import * as GTM from '../../util/GTM'

const useStyles = makeStyles(theme =>
    createStyles({
        icon: {
            marginTop: '70px',
            color: theme.palette.primary.main,
            fontSize: '60px'
        },
        title: {
            marginTop: '20px',
            marginBottom: '40px'
        },
        footer: {
            maxWidth: '506px',
            margin: 'auto',
            fontSize: '12px'
        }
    })
)

interface AccountCreatedProps {
    accountDetails: AccountDetails
}

/**
 * Account created page (shown once your account has been succesfully created).
 */
const AccountCreated = ({ accountDetails } : AccountCreatedProps) : JSX.Element => {
    const classes = useStyles()

    useEffect(() => {
        // Wait for a bit so the user can see the message,
        // then automatically navigate them to the Configure app.
        setTimeout(() => {
            window.location = accountDetails.redirect_url
        }, 3000)
    })

    useEffect(() => {
        GTM.transactionComplete(accountDetails)
    }, [])

    return (
        <>
            <CheckAltIcon className={classes.icon} />

            <PageTitle remoteConfigID="signup_account_created_title" className={classes.title} />

            <Typography remoteConfigID="signup_account_created_footer" variant="body2" className={classes.footer} />
        </>
    )
}

export default AccountCreated
