import React from 'react'
import TextField from 'text-field-mui'

interface ZipCodeTextFieldProps {
    countryCode: string,
    value: string,
    onChange: (newValue: string) => void
}

/**
 * Textfield component that provides format-as-you type functionality for entering a zip
 * or postal code (depending on the specified country)
 */
const ZipCodeTextField = ({ countryCode, value, onChange, ...props } : ZipCodeTextFieldProps) : JSX.Element => {
    const liveFormatPostalCode = (postalCode: string) => {
        postalCode = postalCode.replace(/[^a-zA-Z\d\s:]/g, '')
        let formatedPostalCode = ''
        let isLastNumeric = true
        Array.from(postalCode).forEach(char => {
            if (formatedPostalCode.length === 7) return
            if (isLastNumeric) {
                if (/^\d+$/.test(char)) return
            } else {
                if (/^[a-zA-Z]+$/.test(char)) return
            }
            if (formatedPostalCode.length === 3) formatedPostalCode += ' '
            if (char === ' ') return
            formatedPostalCode += char.toUpperCase()
            if (formatedPostalCode.length === 3 && window.prevPostalCode?.length === 2) formatedPostalCode += ' '
            isLastNumeric = !isLastNumeric
        })
        window.prevPostalCode = formatedPostalCode
        return formatedPostalCode
    }

    const onTextFieldChange = event => {
        let formatted = ''
        if (countryCode === 'CA') {
            formatted = liveFormatPostalCode(event.target.value).slice(0, 7)
        } else {
            formatted = event.target.value.replace(/\D/g, '').slice(0, 5)
        }
        onChange(formatted)
    }

    const onXClick = () => {
        onChange('')
    }

    return (
        <TextField
            data-testid="billing_zip_code"
            type="text"
            label={countryCode === 'CA' ? 'Postal code' : 'Zip code'}
            value={value}
            maxLength={countryCode === 'CA' ? 7 : 5}
            onChange={onTextFieldChange}
            onXClick={onXClick}
            {...props} />
    )
}

export default ZipCodeTextField
