import React, { useContext, useState } from 'react'
import * as API from '../../util/API'
import * as GTM from '../../util/GTM'
import { ReserveNumberContext } from './ReserveNumberContext'
import { CartItem, PhoneNumberData } from '../../types'
import LeadContext from '../../LeadContext'

interface ReserveNumberContextProviderProps {
    children: JSX.Element
}

/**
 * This context provides components throughout the pick-a-number step with access to state related to
 * reserving a number in order to purchase it.
 * This includes the status of the request, functions to reserve a number, and in the future numbers that
 * were found to be already taken.
 */
const ReserveNumberContextProvider = ({ children } : ReserveNumberContextProviderProps) : JSX.Element => {
    const [isReservingNumber, setIsReservingNumber] = useState<boolean>(false)
    const [choosenPhoneNumber, setChoosenPhoneNumber] = useState<PhoneNumberData | null>(null)
    const [numbersTaken, setNumbersTaken] = useState<PhoneNumberData[]>([])
    const [error, setError] = useState<Error | null>(null)

    const { addToCart } = useContext(LeadContext)

    const reserveNumber = async (phoneNumberData: PhoneNumberData) => {
        setChoosenPhoneNumber(phoneNumberData)
        setIsReservingNumber(true)

        let cartItem: CartItem | null = null
        try {
            const response = await API.reserveNumber(phoneNumberData)
            cartItem = response.data
            if (cartItem) {
                addToCart(cartItem)
            }
        } catch (error) {
            if (error.message.includes('got to that number first')) {
                GTM.numberAlreadyTaken(phoneNumberData.phone_number)
                setNumbersTaken([...numbersTaken, phoneNumberData])
            } else {
                GTM.reserveNumberError(error)
            }
            setError(error)
        }

        setIsReservingNumber(false)

        return cartItem
    }

    return (
        <ReserveNumberContext.Provider value={{ reserveNumber, isReservingNumber, choosenPhoneNumber, error, numbersTaken }}>
            {children}
        </ReserveNumberContext.Provider>
    )
}

export default ReserveNumberContextProvider
