import React, { useState, useEffect } from 'react'
import Spinner from 'spinner'
import SomethingWentWrong from '../../SomethingWentWrong'
import axios from 'axios'

interface StripeLibraryProviderProps {
    children: React.ReactChildren;
}

/**
 *  Manually loads the Stripe JS library that is normally loaded internally by the <StripeProvider />.
 *  This allows us to track and report the causes of why the library is sometimes not being loaded (eg NetworkError or Script being Blocked)
 */
const StripeLibraryProvider = ({ children }: StripeLibraryProviderProps): JSX.Element => {
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<null | Error>(null)

    const loadStripe = async () => {
        try {
            // For PCI compliance puses, you must load Stripe.js directly from Stripe’s servers at runtime.
            // You can’t install it from npm or bundle it into your application like a conventional dependency.
            const response = await axios.get('https://js.stripe.com/v3/')
            eval(response.data) // eslint-disable-line no-eval
        } catch (error) {
            console.error(`StripeLibraryProvider: error loading stripe library: ${error}`)
            setError(error as Error)
        }
        setLoading(false)
    }

    useEffect(() => {
        loadStripe()
    }, [])

    return (
        <>
            {loading
                ? (
                    <Spinner size="sm"/>
                )
                : error
                    ? (
                        <SomethingWentWrong />
                    )
                    : (
                        children
                    )}
        </>
    )
}

export default StripeLibraryProvider
