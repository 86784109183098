import React, { useState } from 'react'
import TextField from 'text-field-mui/src'
import { createStyles, makeStyles } from '@material-ui/core'
import IconButton from 'icon-button-mui/src'
import { AddIcon } from 'pdc-svg-icons/SvgIcons'
import Typography from 'typography'
import * as GTM from '../../../util/GTM'

/**
 * Similar to the CancelIcon pdc-svg-icons, but differs and style and has no outer circle
 */
const CancelIcon = () => (
    <svg data-testid="x-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path d="M6 6L14.5 14.5" stroke="white" strokeWidth="2" fill="white" />
        <path d="M14.5 6L6 14.5" stroke="white" strokeWidth="2" fill="white" />
    </svg>
)

const useStyles = makeStyles(theme =>
    createStyles({
        couponForm: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'left',
            margin: '10px 0'
        },
        iconButton: {
            width: '22px',
            height: '22px',
            marginRight: '8.5px',
            padding: '0px',
            '& svg': {
                width: '12px',
                height: '12px'
            }
        },
        addIcon: {
        },
        cancelIcon: {
            '& svg': {
                width: '16px',
                height: '16px'
            }
        },
        textField: {
            height: '38px'
        },
        footer: {
            marginTop: '17px',
            marginBottom: '0px'
        },
        couponDescription: {
            marginTop: '20px',
            marginBottom: '0px',
            textAlign: 'left',
            fontWeight: 600,
            fontSize: '10px',
            lineHeight: '12px',
            letterSpacing: '0.4px',
            textTransform: 'uppercase'
        }
    })
)

interface CouponProps {
    setCouponCode: (text : string) => void,
    couponCodeApplied: string | null,
    couponCodeAttempted: string | null,
    couponDesc: string | null,
    disabled?: boolean
}

/**
 * Coupon entry/display widget, as part of the OrderSummary
 */
const Coupon = ({ setCouponCode, couponCodeApplied, couponCodeAttempted, couponDesc, disabled } : CouponProps) : JSX.Element => {
    const [text, setText] = useState<string>('')
    const [focused, setFocused] = useState<boolean>(false)

    const classes = useStyles()

    const onSubmit = event => {
        event.preventDefault()
        event.stopPropagation()
        couponCodeApplied ? clearCoupon() : setCouponCode(text)
    }

    const clearCoupon = () => {
        setCouponCode('')
        GTM.couponCleared()
    }

    let textFieldValue = text
    if (couponCodeApplied) {
        // Note: the backend returns "coupon_code": "error" rather than than actual code attempted,
        // so display the code that was attempted instead of "error"
        textFieldValue = couponCodeApplied === 'error' ? couponCodeAttempted : couponCodeApplied
    }

    return (
        <form className={classes.couponForm} onSubmit={onSubmit}>
            <TextField
                data-testid="coupon-textfield"
                placeholder='Enter coupon code'
                className={classes.textField}
                size="small"
                value={textFieldValue}
                onChange={e => setText(e.target.value.toUpperCase())}
                disabled={Boolean(couponCodeApplied) || disabled}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                fullWidth
                InputProps={{
                    endAdornment: (
                        <IconButton disabled={disabled} type="submit" color={!couponCodeApplied && focused ? 'primary' : 'secondary'} className={`${classes.iconButton} ${couponCodeApplied ? classes.cancelIcon : classes.addIcon}`}>
                            {couponCodeApplied
                                ? (
                                    <CancelIcon data-testid="coupon-remove-button" />
                                )
                                : (
                                    <AddIcon data-testid="coupon-add-button"/>
                                )}
                        </IconButton>
                    )
                }}
            />

            {couponCodeApplied &&
                <>
                    {couponCodeApplied === 'error'
                        ? (
                            <Typography variant="helperText" align="left" color="#E85646" className={classes.footer}>
                                That coupon code is invalid.
                            </Typography>
                        )
                        : (
                            <Typography variant="helperText" align="left" color="#197F9F" className={classes.footer}>
                                Your coupon has been applied.
                            </Typography>
                        )}
                </>
            }

            {(couponDesc && couponCodeApplied !== 'error')
                ? (
                    <p className={classes.couponDescription}>
                        {couponDesc}
                    </p>
                )
                : null}
        </form>
    )
}

export default Coupon
