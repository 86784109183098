import React from 'react'
import { Bill } from '../../../types'
import Item from './Item'

interface PhoneNumberChargesProps {
    bill: Bill
}

/**
 * Listing of phone numbers being purchased, as part of the OrderSummary
 */
const PhoneNumberCharges = ({ bill } : PhoneNumberChargesProps) : JSX.Element => {
    const section = bill.details.find(s => s.section === 'phone_numbers')
    const charges = section ? section.charges : []

    return (
        <>
            {charges.map((charge, idx) => <Item key={idx} label={charge.name} cost={charge.cost} />)}
        </>
    )
}

export default PhoneNumberCharges
