import React, { ReactNode } from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import Typography from 'typography'

const useStyles = makeStyles(() =>
    createStyles({
        pageTitle: {
            marginBottom: '25px'
        }
    })
)

interface PageTitleProps {
    children?: ReactNode,
    className?: string,
    remoteConfigID?: string
}

/**
 * Custom blue-ish, left aligned headings that are unique to signup
 */
const PageTitle = (props: PageTitleProps) => {
    const classes = useStyles()

    return (
        <Typography variant="h4" align="center" className={`${classes.pageTitle} ${props.className}`} color="textPrimary" {...props}>
            {props.children}
        </Typography>
    )
}

export default PageTitle
