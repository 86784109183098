import React, { useContext } from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import { PhoneNumberData } from '../../types'
import { formatNumberWithAlphaMask } from 'phone-numbers'
import { ReserveNumberContext } from './ReserveNumberContext'
import Spinner from 'spinner'
import * as GTM from '../../util/GTM'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import { getCookieValue } from '../../util/Cookies'

// Custommized call icon with background
const CallIcon = () => (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" width="24" height="24" rx="12" fill="#C0E1EA" fillOpacity="0.75"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M9.91328 11.3113C10.5632 12.7069 11.6482 13.8655 12.995 14.616L14.0982 13.5872C14.2336 13.4609 14.429 13.4241 14.5965 13.4882C15.133 13.6864 15.7159 13.8038 16.3167 13.8248C16.5832 13.8341 16.7937 14.0597 16.7844 14.3262L16.7253 16.0173C16.716 16.2838 16.4903 16.4943 16.2238 16.485C11.6739 16.3261 8.11521 12.5098 8.27409 7.95989C8.2834 7.69339 8.50906 7.48296 8.77557 7.49226L10.4715 7.55149C10.738 7.56079 10.9484 7.78646 10.9391 8.05296C10.918 8.65865 10.9946 9.2435 11.1549 9.79246C11.2023 9.96391 11.1569 10.1515 11.0165 10.2825L9.91328 11.3113Z" fill="#186A82"/>
    </svg>
)

// Customized X icon with a transclucent background
const RemoveIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="24" height="24" rx="12" fill="#FF7A6B" fillOpacity="0.3"/>
        <path d="M16.2879 8.61856C16.405 8.5014 16.405 8.31145 16.2879 8.1943L15.8057 7.71213C15.6885 7.59497 15.4986 7.59497 15.3814 7.71213L12 11.0936L8.61856 7.71213C8.5014 7.59497 8.31145 7.59497 8.1943 7.71213L7.71213 8.1943C7.59497 8.31145 7.59497 8.5014 7.71213 8.61856L11.0936 12L7.71213 15.3814C7.59497 15.4986 7.59497 15.6885 7.71213 15.8057L8.1943 16.2879C8.31145 16.405 8.5014 16.405 8.61856 16.2879L12 12.9064L15.3814 16.2879C15.4986 16.405 15.6885 16.405 15.8057 16.2879L16.2879 15.8057C16.405 15.6885 16.405 15.4986 16.2879 15.3814L12.9064 12L16.2879 8.61856Z" fill="#E85646"/>
    </svg>
)

interface PhoneNumberProps {
    phoneNumber: PhoneNumberData,
    highlight: boolean,
    isVanityNumber: boolean
}

const useStyles = makeStyles(theme =>
    createStyles({
        box: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',

            height: '94px',
            [theme.breakpoints.up('md')]: {
                width: '210px',
                height: '66px'
            },
            [theme.breakpoints.down('sm')]: {
                '& svg': {
                    width: '30px',
                    height: '30px'
                }
            },

            background: '#FFFFFF',
            '&:hover': {
                outline: '8px solid #8C98A026',
                boxSizing: 'border-box'
            },
            borderRadius: '3px',

            position: 'relative',
            fontSize: '1.2em',
            cursor: 'pointer'
        },
        numberTaken: {
            backgroundColor: theme.palette.error.background,
            color: theme.palette.error.main
        },
        shadowed: {
            boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)'
        },
        centralArea: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',

            width: '208px',
            height: '28px'
        },
        spinner: {
            width: '24px',
            height: '24px',
            marginRight: '10px',
            color: theme.palette.text.secondary
        },
        reservingNumberText: {
            fontWeight: 600,
            fontSize: '10px',
            lineHeight: '12px',
            color: theme.palette.text.secondary
        },
        reservingRecommendNumberText: {
            color: 'white'
        },
        numberText: {
            marginLeft: '10px',
            fontSize: '16px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '20px'
            },
            display: 'flex',
            alignItems: 'center'
        },
        letters: {
            fontWeight: 600
        },
        priceTag: {
            borderRadius: '2px',
            backgroundColor: '#e6f7e3',
            color: '#29921B',
            zIndex: 1,
            position: 'absolute',
            top: '2px',
            right: '2px',
            fontSize: '11px',
            padding: '0px 10px'
        },
        highlighted: {
            marginTop: '20px',
            marginBottom: '20px',
            marginLeft: 'auto',
            marginRight: 'auto',
            backgroundColor: '#3FAE29',
            color: 'white',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
                maxWidth: '252px',
                height: '80px'
            }
        },
        highlightedPriceTag: {
            color: 'white',
            backgroundColor: theme.palette.primary.textDark
        }
    })
)

/**
 * Displays an individual number that the user can pick
 */
const PhoneNumber = ({ phoneNumber, highlight, isVanityNumber = false }: PhoneNumberProps) => {
    const history = useHistory()

    const { reserveNumber, isReservingNumber, choosenPhoneNumber, numbersTaken } = useContext(ReserveNumberContext)

    const classes = useStyles()

    const formattedNumber = formatNumberWithAlphaMask(phoneNumber.phone_number, phoneNumber.format_mask, { useAreaCodeBrackets: true })

    const onClick = async () => {
        if (!isReservingNumber) {
            GTM.reserveNumber(phoneNumber.phone_number, phoneNumber.price, highlight) // indicates user is (attempting) to reserve a number

            const params = { ...phoneNumber }
            if (isVanityNumber) {
                params.vanity_number = formattedNumber /* eslint-disable-line @typescript-eslint/naming-convention */
            }
            await reserveNumber(params)

            history.push(`/business-details?session_token=${getCookieValue('pdc_lead_session_token')}`)
        }
    }

    // Renders the phone number so the letters are rendered bold/strong
    const renderPhoneNumber = (formattedNumber: string) => {
        const segments = []
        let word = formattedNumber[0]
        for (let i = 1; i < formattedNumber.length; i++) {
            if (formattedNumber[i].match(/[A-Z]/) === formattedNumber[i - 1].match(/[A-Z]/)) {
                word += formattedNumber[i] // previous char and current char are both letters or both non-letters
            } else {
                segments.push(word)
                word = formattedNumber[i]
            }
        }
        if (word) {
            segments.push(word)
        }
        return (
            <>
                {segments.map((seg, idx) => seg.match(/[A-Z]/) ? <span key={idx} className={classes.letters}>{seg}</span> : seg)}
            </>
        )
    }

    const isNumberTaken = numbersTaken.some((takenNumber: PhoneNumberData) => takenNumber.phone_number === phoneNumber.phone_number)

    const boxClasses = classNames(classes.box, {
        [`${classes.numberTaken}`]: isNumberTaken,
        [`${classes.highlighted}`]: highlight,
        [`${classes.shadowed}`]: !highlight
    })

    return (
        <div data-testid="phone-number" onClick={onClick} className={boxClasses}>
            {(isReservingNumber && choosenPhoneNumber.phone_number === phoneNumber.phone_number)
                ? (
                    <>
                        <Spinner color={highlight ? 'white' : 'secondary'} className={classes.spinner} />
                        <span className={`${classes.reservingNumberText} ${highlight ? classes.reservingRecommendNumberText : ''}`}>
                        RESERVING NUMBER
                        </span>
                    </>
                )
                : (
                    <>
                        {phoneNumber.price && !isNumberTaken
                            ? (
                                <span className={`${classes.priceTag} ${highlight && classes.highlightedPriceTag}`}>
                                    {phoneNumber.price ? `$${phoneNumber.price}` : 'FREE'}
                                </span>
                            )
                            : null}

                        <>
                            {isNumberTaken
                                ? (
                                    <RemoveIcon />
                                )
                                : !highlight
                                    ? (
                                        <CallIcon />
                                    )
                                    : null}

                            <span className={classes.numberText}>
                                {renderPhoneNumber(formattedNumber)}
                            </span>
                        </>
                    </>
                )}
        </div>
    )
}

export default PhoneNumber
