import React from 'react'
import GlobalZipCodeTextField from 'zip-code-text-field'
import { createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() =>
    createStyles({
        textField: {
            textAlign: 'left',
            height: '44px'
        }
    })
)

/**
 * Customized ZipCodeTextField, for usage specifically throughout the sign up page.
 */
const ZipCodeTextField = ({ ...props }) => {
    const classes = useStyles()

    return (
        <GlobalZipCodeTextField
            className={classes.textField}
            fullWidth
            InputProps={{
                endAdornment: null
            }}
            {...props} />
    )
}

export default ZipCodeTextField
