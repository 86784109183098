import React, { useState } from 'react'
import { createStyles, makeStyles, TextField } from '@material-ui/core'
import { SearchIcon } from 'svg-icons/src'
import IconButtonMui from 'icon-button-mui'
import Typography from 'typography'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() =>
    createStyles({
        customSearch: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: '1em'
        },
        description: {
            marginBottom: '20px'
        },
        searchBar: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '50px'
        },
        searchInput: {
            textAlign: 'center',
            fontSize: '2em',
            margin: '0 20px',
            '& input': {
                textAlign: 'center',
                fontSize: '30px',
                maxWidth: '250px'
            }
        },
        searchButton: {
            width: '64px',
            height: '64px'
        },
        bulletPoints: {
            textAlign: 'left'
        }
    })
)

/**
 *  The search form/UI for searching for numbers containing a given string of text
 */
const CustomNumberEnterText = () : JSX.Element => {
    const history = useHistory()
    const [text, setText] = useState<string>('')

    const classes = useStyles()

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Only allow a mix of up to 7 letters and/or numbers, no white space of non alphanumeric chars
        const searchText = event.target.value.toUpperCase().replace(/[^A-Z0-9]/gm, '').replace(/\s/g, '').slice(0, 7)
        setText(searchText)
    }

    const onSubmit = event => {
        event.preventDefault()
        if (text.length) {
            history.push(`/pick-a-number/custom/search/${text}`)
        }
    }

    return (
        <div className={classes.customSearch}>
            <Typography remoteConfigID="signup_custom_numbers_search_description" variant="body1" className={classes.description} />

            <form onSubmit={onSubmit} className={classes.searchBar}>
                <TextField
                    data-testid="custom-number-search-textfield"
                    autoFocus={true}
                    className={classes.searchInput}
                    variant="standard" value={text}
                    onChange={onChange} />

                <IconButtonMui
                    data-testid="custom-number-search-submit-button"
                    type="submit"
                    disabled={text.length < 2}
                    color="primary"
                >
                    <SearchIcon color="white" />
                </IconButtonMui>
            </form>

            <small>
                <ul className={classes.bulletPoints}>
                    <li>Search using up to 7 letters or numbers</li>
                    <li>You must enter at least 2 characters.</li>
                    <li>Shorter terms are more likely to get a match.</li>
                    <li>Custom numbers have a one-time fee that won&apos;t affect your monthly service fee.</li>
                    <li>Some examples are: 1 800 FLOWERS, 1 916 342 TACO, 1 80 STYLE.</li>
                </ul>
            </small>
        </div>
    )
}

export default CustomNumberEnterText
