import React from 'react'
import { Bill, Charge } from '../../../types'
import Item from './Item'

interface ItemGroup {
    name: string,
    quantity: number,
    unitCost: number | string,
    total: number | string,
    priority: number
}

const redactedCharges = [
    'Default Extension',
    'Unlimited Base plan',
    'User plan',
    'VOIP_LIMITED_VIRTUAL_COMPANYINBOX',
    'VOIP_UNLIMITED_VIRTUAL_COMPANYINBOX'
]

interface UserChargesProps {
    bill: Bill
}

const priorityByGroupName = {
    'Basic User': 1,
    'Plus User': 2,
    'Pro User': 3
}

/**
 * Listing of charges based on number of users and type, as part of the OrderSummary
 */
const UserCharges = ({ bill } : UserChargesProps) : JSX.Element | null => {
    const extensionsSection = bill.details.filter(s => s.section === 'extensions')
    const planSection = bill.details.filter(s => s.section === 'plan')

    if (!extensionsSection && !planSection) {
        return null
    }

    const allCharges = [
        ...extensionsSection[0].charges,
        ...planSection[0].charges
    ]

    const charges = allCharges.filter(c => !redactedCharges.includes(c.name))

    const groups: ItemGroup[] = []
    charges.forEach((charge: Charge) => {
        // Form groups based on the charges' name and unit cost, while totalling the cost
        const group = groups.find(g => g.name === charge.name)
        if (!group) {
            groups.push({ name: charge.name, quantity: 1, unitCost: charge.cost, total: charge.cost, priority: priorityByGroupName[charge.name] || 4 })
        } else {
            group.quantity += 1
            if (typeof charge.cost === 'number') {
                group.total += charge.cost
            }
        }
    })

    return (
        <>
            {groups.sort((g1, g2) => g1.priority - g2.priority).map((group, idx) => {
                const { name, quantity, unitCost, total } = group

                let label = `${quantity} ${name}${quantity > 1 ? 's' : ''}`
                if (typeof unitCost === 'number') {
                    label += ` x $${parseFloat(unitCost.toString()).toFixed(2)}`
                }

                return (
                    <Item
                        data-testid="item"
                        key={idx}
                        label={label}
                        cost={`${total}`}
                    />
                )
            })}
        </>
    )
}

export default UserCharges
