import React from 'react'
import Switch from 'switch'
import { createStyles, makeStyles } from '@material-ui/core'
import Typography from 'typography'
import * as GTM from '../../../util/GTM'

const useStyles = makeStyles(() =>
    createStyles({
        layout: {
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'stretch'
        },
        billingCycle: {
            display: 'flex',
            flexDirection: 'column',
            flex: '1 0 0'
        },
        switchContainer: {
            display: 'flex',
            alignItems: 'center'
        },
        switch: {
            marginLeft: '6px',
            marginRight: '0px !important'
        },
        switchRoot: {
            margin: 0
        },
        footer: {
            display: 'block',
            width: '146px',
            marginLeft: 'auto',
            marginRight: '0px'
        }
    })
)

interface BillingCycleProps {
    paymentPeriod: number,
    onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void,
    disabled?: boolean
}

/**
 * UI to toggle between monthly and annual billing
 */
const BillingCycle = ({ paymentPeriod, onChange, disabled } : BillingCycleProps) : JSX.Element => {
    const classes = useStyles()

    const onSwitchChanged = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        GTM.paymentPeriodChanging(checked ? 12 : 1)
        onChange(event, checked)
    }

    return (
        <>
            <div className={classes.layout}>
                <Typography remoteConfigID="signup_order_summary_billing_cycle" variant="subtitle3" color="#197F9F" align="left" className={classes.billingCycle} />

                <div className={classes.switchContainer}>
                    <Typography remoteConfigID="signup_order_summary_billing_cycle_monthly" variant="subtitle3" color={paymentPeriod === 1 ? '#197F9F' : '#6E7A82'} />

                    <Switch
                        className={classes.switch}
                        data-testid='payment-period-switch'
                        checked={paymentPeriod === 12}
                        onChange={onSwitchChanged}
                        disabled={disabled}
                    />

                    <Typography remoteConfigID="signup_order_summary_billing_cycle_annually" variant="subtitle3" color={paymentPeriod === 1 ? '#6E7A82' : '#197F9F'} />
                </div>
            </div>
            <Typography remoteConfigID="signup_order_summary_billing_cycle_annual_savings" variant="helperText" component="p" color="#6E7A82" align="right" className={classes.footer} />
        </>
    )
}

export default BillingCycle
