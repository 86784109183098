import { Lead } from '../types'
import * as API from './API'
import { parsePhoneNumberWithError, ParseError } from 'libphonenumber-js'

/**
 *
 */
export class ValidationError extends Error {
    errorCode: undefined | number;

    /**
     *
     */
    constructor (message: string, errorCode?: number) {
        super(message)
        this.errorCode = errorCode
    }
}

/**
 * Detects any problems in a lead, traversing the fields in order and raising the first error found
 * leadParams is some subset of the data for a lead, this may not be a complete set of lead information.
 */
export async function validateLeadParams (leadParams: Lead) {
    const requiredFields = {
        company_name: 'Company name', // eslint-disable-line @typescript-eslint/naming-convention
        first_name: 'First name', // eslint-disable-line @typescript-eslint/naming-convention
        last_name: 'Last name', // eslint-disable-line @typescript-eslint/naming-convention
        email: 'Email',
        personal_phone_number: 'Mobile number', // eslint-disable-line @typescript-eslint/naming-convention
        password: 'Password',
        street: 'Billing address',
        country: 'Country',
        billing_country_code: 'Country', // eslint-disable-line @typescript-eslint/naming-convention
        state: leadParams.billing_country_code === 'CA' ? 'Province' : 'State',
        city: 'City',
        billing_zip_code: leadParams.billing_country_code === 'CA' ? 'Postal code' : 'Zip code' // eslint-disable-line @typescript-eslint/naming-convention
    }

    const validateNameField = nameField => {
        if ((leadParams[nameField] || '').length < 2) {
            throw new ValidationError(`${requiredFields[nameField]} must be at least 2 characters long`)
        }
    }

    const fieldValidators = {
        first_name: () => validateNameField('first_name'), // eslint-disable-line @typescript-eslint/naming-convention
        last_name: () => validateNameField('last_name'), // eslint-disable-line @typescript-eslint/naming-convention
        email: () => validateEmail(leadParams.email || ''),
        personal_phone_number: () => validatePhoneNumber(leadParams.personal_phone_number || ''), // eslint-disable-line @typescript-eslint/naming-convention
        password: () => validatePassword(leadParams.password || '')
    }

    const checkFieldForBlankness = (field: string) => {
        if (/^\s*$/.test(leadParams[field] || '')) {
            throw new ValidationError(`${requiredFields[field]} is required`)
        }
    }

    // Run all field validations for all fields that were submitted
    Object.keys(leadParams).forEach(field => {
        if (field in requiredFields) {
            checkFieldForBlankness(field)
        }
        if (field in fieldValidators) {
            fieldValidators[field]()
        }
    })

    if ('country' in leadParams && 'billing_zip_code' in leadParams) {
        await validateZipCode(leadParams.country || '', leadParams.billing_zip_code || '')
    }

    return leadParams
}

function validateEmail (email: string) {
    if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)) {
        throw new ValidationError('Please enter a valid email address')
    }
    return null
}

/**
 * Returns true if the string follows the format of a valid email address
 */
export function isEmailValid (email: string) {
    try {
        validateEmail(email)
        return true
    } catch (error) {
        return false
    }
}

function validatePassword (password: string) {
    if (password.length < 8) {
        throw new ValidationError('Password must be at least 8 characters long')
    }

    if (!/[A-Z]+/.test(password)) {
        throw new ValidationError('Password must contain at least one capital letter')
    }

    if (!/[a-z]+/.test(password)) {
        throw new ValidationError('Password must contain at least one lower case letter')
    }
}

function validatePhoneNumber (number: string) {
    if (!number) {
        throw new ValidationError('Must enter a mobile number')
    }
    let parsedNumber
    try {
        parsedNumber = parsePhoneNumberWithError(number, 'US')
    } catch (error) {
        if (error instanceof ParseError) {
            // Not a phone number, non-existent country, etc.
            throw new ValidationError('Please enter a valid phone number')
        } else {
            throw error
        }
    }
    if (!parsedNumber || !parsedNumber.isValid()) {
        throw new ValidationError('Please enter a valid phone number')
    }
}

async function validateZipCode (country: string, zipCode: string) {
    const zipCodeVocab = country === 'Canada' ? 'postal code' : 'zip code'
    if (!zipCode) {
        throw new ValidationError(`Must enter a ${zipCodeVocab}`)
    }

    await API.checkZipCode(zipCode).catch(error => {
        if (error.message.toLowerCase().includes('validation errors')) { // the specific message here and in error.errors is less than ideal, so replace with a better error message
            throw new ValidationError(`The ${zipCodeVocab} you entered is not valid`)
        }
    })
}
