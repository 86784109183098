import React from 'react'
import { Button, Container } from '@material-ui/core'
import { removeCookie } from './util/Cookies'
import Typography from 'typography'
import { redirectToPricingPage } from '../src/util/API'

/**
 *  Error UI suitable for use as fallback UI for react error boundaries and such.
 *  NOTE: be careful of dependencies you can not be reliant on things that may not have succeded (eg ThemeProvider / useStyles())
 */
const SomethingWentWrong = () : JSX.Element => {
    const onStartOver = () => {
        removeCookie('pdc_lead_session_token')
        redirectToPricingPage()
    }

    return (
        <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', marginTop: '5em' }}>
            <Typography variant="h3" remoteConfigID="signup_error_boundary_title" />
            <br />

            <Typography paragraph={true} variant="body1" remoteConfigID="signup_error_boundary_description" />

            <Typography paragraph={true} variant="body1" remoteConfigID="signup_error_boundary_contact_info" />

            <Button variant="contained" onClick={onStartOver}>
                Start Over
            </Button>
        </Container>
    )
}

export default SomethingWentWrong
