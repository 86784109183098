import React from 'react'
import {
    Switch,
    Route,
    Redirect
} from 'react-router-dom'
import PageLayout from './PageLayout'

/**
 * URL Routes for sign-up, as used by react-router
 */
const AppRoutes = () : JSX.Element => {
    return (
        <Switch>
            <Route exact path="/">
                <Redirect to="/pick-a-number" /> {/* First step of signup */}
            </Route>
            <Route
                path="/:step"
                render={({ match }) => <PageLayout currentStepId={match.params.step} />}>
            </Route>
        </Switch>
    )
}

export default AppRoutes
