import React from 'react'

/**
 * Context to be provided to all number picker components that are interested in
 * starting the process of reserving a number and/or listening on the status of whether a number
 * is being reserved.
 */
export const ReserveNumberContext = React.createContext({
    reserveNumber: () => { throw new Error('You must render this component inside a ReserveNumberContextProvider to provide a reserveNumber() implementation') },
    isReservingNumber: false,
    choosenPhoneNumber: null,
    numbersTaken: []
})
