import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import PageTitle from '../../PageTitle'
import ProgressBar from '../../design-components/ProgressBar'
import Typography from 'typography'

const useStyles = makeStyles(() =>
    createStyles({
        progressBar: {
            width: '100%',
            maxWidth: '400px',
            height: '10px',
            marginTop: '40px',
            marginBottom: '25px',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    })
)

/**
 * Creating your account
 */
const CreatingYourAccount = () : JSX.Element => {
    const classes = useStyles()

    return (
        <>
            <PageTitle remoteConfigID="signup_creating_your_account_title" />

            <ProgressBar className={classes.progressBar} />

            <Typography remoteConfigID="signup_creating_your_account_footer" variant="body1" />
        </>
    )
}

export default CreatingYourAccount
