import React, { MouseEventHandler } from 'react'
import { createStyles, makeStyles, SvgIconProps, Theme } from '@material-ui/core'
import Typography from 'typography'

interface NumberOptionTileProps {
    id: string,
    renderIcon: (props: SvgIconProps) => JSX.Element,
    remoteConfigID: string
    img: string,
    onClick: MouseEventHandler
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tile: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            height: '150px',
            marginBottom: '20px',
            justifyContent: 'center',
            borderRadius: '5px',
            [theme.breakpoints.up('md')]: {
                justifyContent: 'flex-start',
                width: '250px',
                height: '200px',
                margin: '10px',
                borderRadius: '15px'
            },
            backgroundSize: 'cover',
            backgroundPosition: 'right',
            cursor: 'pointer',
            '&:hover': {
                boxShadow: '0px 0px 0px 10px rgba(63, 174, 41, 0.25)'
            }
        },
        icon: {
            [theme.breakpoints.up('md')]: {
                marginTop: '60px'
            },
            marginBottom: '5px'
        },
        label: {
            fontFamily: 'Montserrat, Helvetica, arial, sans-serif',
            fontSize: '19px !important',
            lineHeight: '22px !important',
            letterSpacing: '1px !important',
            textTransform: 'uppercase',
            color: '#FFF',
            maxWidth: '120px'
        }
    })
)

/**
 * A clickable tile used to display a single number picking option (eg. toll-free/local/...)
 */
const NumberOptionTile = ({ id, renderIcon, remoteConfigID, img, onClick }: NumberOptionTileProps) : JSX.Element => {
    const classes = useStyles()

    return (
        <div id={id} className={classes.tile} onClick={onClick} style={{ backgroundImage: `url(${img})` }}>
            {renderIcon({ className: classes.icon })}
            <Typography align="center" className={classes.label} remoteConfigID={remoteConfigID} />
        </div>
    )
}

export default NumberOptionTile
