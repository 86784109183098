import React, { useState, useContext, useEffect } from 'react'
import Spinner from 'spinner'
import Alert, { Color as AlertColor } from '../../../design-components/Alert'
import BillSections from './BillSections'
import Heading from '../../../Heading'
import { createStyles, makeStyles } from '@material-ui/core'
import orderSummaryContext from './OrderSummaryContext'
import LeadContext from '../../../LeadContext'
import { CartEmptyError } from '../../../util/API'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(() =>
    createStyles({
        orderSummary: {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginBottom: '20px'
        }
    })
)

interface OrderSummaryProps {
    disabled?: boolean
}

/**
 * Dislays the state of your cart and the totals/prices associated with your order
 */
const OrderSummary = ({ disabled } : OrderSummaryProps) : JSX.Element => {
    const classes = useStyles()

    const history = useHistory()

    const { leadParams, setPaymentPeriod, setCouponCode } = useContext(LeadContext)
    const { loading, bill, error } = useContext(orderSummaryContext)

    const [isRedirecting, setIsRedirecting] = useState<boolean>(false)

    useEffect(() => {
        if (!isRedirecting && !loading && error instanceof CartEmptyError) {
            setIsRedirecting(true)
            alert('It looks like your number reservation has expired, you will be redirected to the number selection step.')
            history.push('/pick-a-number')
        }
    }, [loading, error])

    return (
        <div className={classes.orderSummary}>
            <Heading remoteConfigID="signup_order_summary_heading" />

            {loading
                ? (
                    <Spinner data-testid="loading-spinner" />
                )
                : error
                    ? (
                        <Alert
                            data-testid="error-alert"
                            showIcon={true}
                            color={AlertColor.ERROR}
                            content={error.message}
                        />
                    )
                    : bill
                        ? (
                            <BillSections
                                bill={bill}
                                billingCountryCode={leadParams.billing_country_code}
                                setPaymentPeriod={setPaymentPeriod}
                                setCouponCode={setCouponCode}
                                disabled={disabled} />
                        )
                        : null}
        </div>
    )
}

export default OrderSummary
