import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import PhoneNumber from './PhoneNumber'
import { PhoneNumberData } from '../../types'

const useStyles = makeStyles(theme =>
    createStyles({
        list: {
            display: 'grid',
            gridGap: '20px',
            gridTemplateColumns: '100%',
            width: '100%',
            maxWidth: '296px',
            [theme.breakpoints.up('md')]: {
                gridTemplateColumns: '210px 210px',
                width: '440px',
                maxWidth: 'unset'
            },
            margin: '30px auto'
        },
        footer: {
            margin: 'auto',
            marginTop: '3em',
            textAlign: 'center'
        }
    })
)

interface NumberListProps {
    numbers: PhoneNumberData[],
    isVanityNumber: boolean
}

/**
 *  Container listing of numbers in a grid of rows/columns
 */
const NumberList = ({ numbers, isVanityNumber = false } : NumberListProps) : JSX.Element => {
    const classes = useStyles()

    return (
        <div className={classes.list}>
            {numbers.map(number => (
                <PhoneNumber
                    key={number.phone_number}
                    phoneNumber={number}
                    isVanityNumber={isVanityNumber} />
            ))}
        </div>
    )
}

export default NumberList
