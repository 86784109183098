import React from 'react'
import Select from 'select'
import USStates from '../../util/us-states/USStates'
import CanadaProvinces from '../../util/canada-provinces/CanadaProvinces'

interface StateSelectorProps {
    countryCode: string, // "CA" or "US",
    value: string, // "" or 2 capital letter abrevication (eg. "CA")
    onChange: (value: string) => void,
    disabled: boolean
}

/**
 * Select dropdown that allows the user to choose a province/state
 */
const StateSelector = ({ countryCode, value, onChange, disabled, ...props } : StateSelectorProps) : JSX.Element => {
    const options = (countryCode === 'CA' ? CanadaProvinces : USStates).map(s => ({ value: s.abbreviation, label: s.name }))

    return (
        <Select
            {...props}
            options={options}
            name="state"
            value={value}
            onChange={event => onChange(event.target.value)}
            fullWidth={true}
            label={countryCode === 'CA' ? 'Province' : 'State'}
            disabled={disabled}
        />
    )
}

export default StateSelector
