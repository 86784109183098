import React, { useEffect } from 'react'
import TextField from '../../design-components/TextField'
import StateSelector from '../../design-components/StateSelector'
import ZipCodeTextField from '../../design-components/ZipCodeTextField'
import Select from 'select'
import { Grid } from '@material-ui/core'
import { Lead } from '../../types'
import PasswordRequirements from './PasswordRequirements'
import { AsYouType } from 'libphonenumber-js'
import Typography from 'typography'
import { LockIcon } from 'svg-icons/src'
import Heading from '../../Heading'
import { createStyles, makeStyles } from '@material-ui/core'
import CreditCardForm from './CreditCardForm'
import * as GTM from '../../util/GTM'
import { isEmailValid } from '../../util/Validations'
import { debounce } from 'debounce'

const useStyles = makeStyles(() =>
    createStyles({
        requiredFieldsText: {
            color: '#197F9F !important',
            marginBottom: '15px'
        },
        footerText: {
            marginTop: '10px'
        },
        select: {
            height: '44px'
        },
        lockIcon: {
            width: '18px',
            height: '18px',
            marginBottom: '4px'
        },
        zipHelperText: {
            marginTop: '7px'
        }
    })
)

interface BusinessInfoFormParams {
    businessInfo: Lead,
    setBusinessInfo: (params: Lead) => void,
    disabled: boolean,
    checkingZip: boolean,
    isZipValid: boolean,
    skipCreditCardEntry: boolean
}

const fieldLabels = {
    company_name: 'Company Name', // eslint-disable-line @typescript-eslint/naming-convention
    first_name: 'First name', // eslint-disable-line @typescript-eslint/naming-convention
    last_name: 'Last Name', // eslint-disable-line @typescript-eslint/naming-convention
    personal_phone_number: 'Mobile number', // eslint-disable-line @typescript-eslint/naming-convention
    password: 'Password',
    email: 'Email',
    street: 'Billing address',
    city: 'City',
    state: 'State',
    billing_zip_code: 'Postal code', // eslint-disable-line @typescript-eslint/naming-convention
    billing_country_code: 'Country' // eslint-disable-line @typescript-eslint/naming-convention
}

const countryOptions = [
    { value: 'US', label: 'United States' },
    { value: 'CA', label: 'Canada' }
]

/**
 * Form to collect business information as part of the sign-up checkout process
 */
const BusinessInfoForm = ({ businessInfo, setBusinessInfo, disabled, checkingZip, isZipValid, skipCreditCardEntry } : BusinessInfoFormParams) : JSX.Element => {
    const classes = useStyles()

    const sendGTMBussinessInfo = debounce(() => {
        GTM.businessInfoChanged(businessInfo)

        if (isEmailValid(businessInfo.email)) {
            GTM.emailProvided(businessInfo.email)
        }
    }, 2000)

    useEffect(() => {
        sendGTMBussinessInfo()
    }, [businessInfo])

    const renderField = (
        field: string,
        fieldType: string,
        onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | undefined
    ) : JSX.Element => {
        const onFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
                onChange(event)
            } else {
                setBusinessInfo({ ...businessInfo, [field]: event.target.value })
            }
        }

        const onXClick = () => {
            setBusinessInfo({ ...businessInfo, [field]: '' })
        }

        return (
            <TextField
                data-testid={field}
                name={field}
                type={fieldType}
                label={fieldLabels[field]}
                InputProps={{
                    name: field // may serve as a hint to browser auto-fill logic as to what the field does
                }}
                value={businessInfo[field] || ''}
                onChange={onFieldChange}
                disabled={disabled}
                onXClick={onXClick}
                fullWidth/>
        )
    }

    return (
        <>
            <Heading>
                Business information
            </Heading>

            <Typography variant="helperText" align="left" display="block" className={classes.requiredFieldsText}>
                All fields are required
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {renderField('company_name', 'text')}
                </Grid>

                <Grid item xs={12} md={6}>
                    {renderField('first_name', 'text')}
                </Grid>
                <Grid item xs={12} md={6}>
                    {renderField('last_name', 'text')}
                </Grid>

                <Grid item xs={12}>
                    {renderField('email', 'email')}
                </Grid>

                <Grid item xs={12} md={6}>
                    {renderField('personal_phone_number', 'tel', event => {
                        // Format the number as the user types, except when the user is removing/backspacing characters (prevents bugs/glitches due to formatting re-introducing things like brackets)
                        const inputValue = event.target.value.slice(0, 15)
                        const formattedNumber = inputValue.length >= (businessInfo.personal_phone_number?.length || 0) ? new AsYouType('US').input(inputValue) : inputValue
                        setBusinessInfo({ ...businessInfo, personal_phone_number: formattedNumber.slice(0, 15) }) // eslint-disable-line @typescript-eslint/naming-convention
                    })}

                    <Typography variant="helperText" display="block" align="left" color="#6E7A82" className={classes.footerText}>
                        Used for verification purposes
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    {renderField('password', 'password')}

                    <PasswordRequirements password={businessInfo.password || ''} />
                </Grid>
            </Grid>

            <Heading>
                Secure checkout <LockIcon className={classes.lockIcon} />
            </Heading>

            <br />

            <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                    {renderField('street', 'text')}
                </Grid>
                <Grid item xs={12} md={4}>
                    {renderField('city', 'text')}
                </Grid>

                <Grid item xs={12} md={4}>
                    <Select
                        className={classes.select}
                        name="country"
                        value={businessInfo.billing_country_code || ''}
                        options={countryOptions}
                        data-testid="billing_country_code"
                        disabled={disabled}
                        label = 'Country'
                        onChange={(event) => {
                            const opt = countryOptions.find(opt => opt.value === event.target.value)

                            setBusinessInfo({
                                ...businessInfo,
                                country: opt?.label,
                                state: '', // Reset this as user will have to pick again
                                billing_zip_code: '', // eslint-disable-line @typescript-eslint/naming-convention
                                billing_country_code: opt?.value // eslint-disable-line @typescript-eslint/naming-convention
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <StateSelector
                        className={classes.select}
                        data-testid="state"
                        countryCode={businessInfo.billing_country_code || 'US'}
                        value={businessInfo.state || ''}
                        onChange={val => {
                            setBusinessInfo({
                                ...businessInfo,
                                state: val
                            })
                        }}
                        disabled={disabled} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ZipCodeTextField
                        countryCode={businessInfo.billing_country_code || 'US'}
                        value={businessInfo.billing_zip_code || ''}
                        onChange={val => {
                            setBusinessInfo({
                                ...businessInfo,
                                billing_zip_code: val // eslint-disable-line @typescript-eslint/naming-convention
                            })
                        }}
                        fullWidth={true}
                        disabled={disabled}
                        error={!checkingZip && !isZipValid}
                    />
                    {checkingZip
                        ? (
                            <Typography variant="helperText" align="left" display="block" className={classes.zipHelperText}>
                            Checking...
                            </Typography>
                        )
                        : !isZipValid
                            ? (
                                <Typography variant="helperText" align="left" display="block" color="#E85646" className={classes.zipHelperText}>
                            This entry is invalid
                                </Typography>
                            )
                            : null}
                </Grid>
                {!skipCreditCardEntry &&
                    <Grid item xs={12}>
                        <CreditCardForm disabled={disabled} />
                    </Grid>
                }
            </Grid>
        </>
    )
}

export default BusinessInfoForm
