import React from 'react'
import { Grid, TextField } from '@material-ui/core'
// For reference, all stripe elements exported:
//  CardElement, CardNumberElement, CardExpiryElement, CardCvcElement, CardCVCElement,
//  PaymentRequestButtonElement, IbanElement, IdealBankElement, FpxBankElement, AuBankAccountElement
import {
    CardElement
} from 'react-stripe-elements'
import InputComponentWrapper from './InputComponentWrapper'

interface CreditCardFormProps {
    disabled: boolean
}

/**
 * Form to collect credit card + billing address as part of the sign-up checkout process
 */
const CreditCardForm = ({ disabled } : CreditCardFormProps) : JSX.Element => {
    return (
        <Grid container spacing={3}>
            {/* Important: for future development it is recommended to switch to Stripe's PaymentElement (and upgrade to @stripe/react-stripe-js to do so)
                alternatively, it is possible to use individuallized elements like: CardNumberElement, CardCVCElement, CardExpiryElement
                however CardElement provides all this + postal code (which pops in as needed) */}
            <Grid item xs={12}>
                <TextField
                    data-testid="credit-card-field"
                    variant="filled"
                    label='' // leave blank as these would float over stripe's input labels
                    required
                    fullWidth
                    disabled={disabled}
                    InputProps={{
                        inputComponent: InputComponentWrapper,
                        inputProps: {
                            component: CardElement
                        }
                    }}
                />
            </Grid>
        </Grid>
    )
}

export default CreditCardForm
