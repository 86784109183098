import React, { useRef } from 'react'

interface StripeInputProps {
    component: React.Component
}

// implement `InputElement` interface as recommended when using a custom component inside a Material UI TextField:
// see: https://mui.com/material-ui/react-text-field/#integration-with-3rd-party-input-libraries
const InputComponentWrapper = (props: StripeInputProps, ref) => {
    const { component, ...other } = props

    const Component = component

    const elementRef = useRef()

    React.useImperativeHandle(ref, () => ({
        focus: () => elementRef.current.focus
    }))

    return (
        <Component
            ref={element => (elementRef.current = element)}
            {...other}
        />
    )
}

export default React.forwardRef(InputComponentWrapper)
