/**
 * Styles for design-components/select
 */
const styles = () => {
    return {
        formControl: {
            position: 'relative'
        },
        select: {
            fontFamily: 'Montserrat',
            fontSize: '14px',
            paddingTop: '20px',
            paddingLeft: '8px',
            paddingRight: '8px',
            display: 'block',
            width: '100%',
            height: '47px',
            backgroundColor: '#F4F5F6',
            '&:hover': {
                backgroundColor: '#e6f7e3',
                transition: '0.3s'
            },
            border: 'none',
            borderBottom: '1px solid #85929AA6'
        },
        option: { // limited ability to style
            fontSize: '14px',
            backgroundColor: 'white',
            fontFamily: 'Montserrat-medium'
        },
        label: {
            fontFamily: 'Montserrat, Helvetica, arial, sans-serif',
            position: 'absolute',
            zIndex: 1,
            left: '12px',
            top: '0px',
            transition: '0.2s ease all',
            pointerEvents: 'none',
            color: 'rgb(54, 64, 71)',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            fontSize: '9.5px',
            fontWeight: 600,
            letterSpacing: '0.2px',
            textAlign: 'left',
            lineHeight: '12px',
            transform: 'translate(0, 9px) scale(1)',
            overflow: 'hidden',
            textTransform: 'uppercase',
            transformOrigin: 'top left'
        },
        placeholderLabel: {
            transform: 'translate(0, 16px) scale(1.5)',
            color: 'rgb(54, 64, 71)',
            fontWeight: 400,
            textTransform: 'unset',
            letterSpacing: 'unset'
        }
    }
}

export default styles
