import React, { ReactNode } from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import Typography from 'typography'

const useStyles = makeStyles(() =>
    createStyles({
        signUpHeading: {
            color: '#197f9f'
        }
    })
)

interface HeadingProps {
    children: ReactNode
}

/**
 * Custom blue-ish, left aligned headings that are unique to signup
 */
const Heading = (props: HeadingProps) => {
    const classes = useStyles()

    return (
        <Typography variant="subtitle1" align="left" className={classes.signUpHeading} {...props}>
            {props.children}
        </Typography>
    )
}

export default Heading
