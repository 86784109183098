import React from 'react'
import { StripeProvider } from 'react-stripe-elements'
import { Elements } from 'react-stripe-elements'
import Checkout from './Checkout'
import StripeLibraryProvider from './StripeLibraryProvider'

interface CheckoutPageProps {
    setShowStepper: (showStepper: boolean) => void,
    setDisableStepper: (disableStepper: boolean) => void
}

/**
 * This component is the high-est level component of the checkout step (step 3) in the sign-up flow.
 *
 * It is separated from the rest of the checkout page which usese injectIntl(), which must be in a (separate)
 * component which is nested under the StripeProvider component.
 */
const CheckoutPage = ({ setShowStepper, setDisableStepper } : CheckoutPageProps) : JSX.Element => {
    return (
        <StripeLibraryProvider>
            <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC_KEY}>
                <Elements>
                    <Checkout
                        setShowStepper={setShowStepper}
                        setDisableStepper={setDisableStepper}
                    />
                </Elements>
            </StripeProvider>
        </StripeLibraryProvider>
    )
}

export default CheckoutPage
