import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import TextField from 'text-field-mui'
import { Autocomplete } from 'autocomplete-mui'
import Spinner from 'spinner'
import Typography from 'typography'

const useStyles = makeStyles(() =>
    createStyles({
        searchBar: {
            width: '300px',
            margin: '2em auto'
        },
        autoComplete: {
            '& input': {
                textAlign: 'center',
                backgroundColor: 'white'
            }
        }
    })
)

interface LocalNumberSearchBarProps {
    areaCodeOptions: string[],
    areaCode: string,
    setAreaCode: (areaCode: string) => void
}

/**
 * A search bar that contains a dropdown / autocomplete where the user can use to search
 * for numbers local to a given area code.
 */
const LocalNumberSearchBar = ({ areaCodeOptions, areaCode, setAreaCode } : LocalNumberSearchBarProps) : JSX.Element => {
    const classes = useStyles()

    if (!areaCodeOptions.length) {
        return (
            <Spinner />
        )
    }

    return (
        <div className={classes.searchBar}>
            <Typography remoteConfigID="signup_local_numbers_enter_an_area_code" variant="body1" />

            <Autocomplete
                className={classes.autoComplete}
                disablePortal
                options={areaCodeOptions}
                autoFocus={true}
                inputValue={areaCode}
                onInputChange={event => {
                    const val = event?.target?.value
                    setAreaCode(typeof val === 'string' ? val.replace(/\D/g, '').substring(0, 3) : '')
                }}
                onChange={val => setAreaCode(val || '')}
                getOptionLabel={opt => opt}
                renderInput={(params) => (
                    <TextField
                        data-testid="local-number-search-textfield"
                        label="Area code"
                        {...params}
                    />
                )}
            />
        </div>
    )
}

export default LocalNumberSearchBar
