import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import OldApp from './src_before_checkout_split/src/App'
import 'core-js/es'
import 'es6-promise/auto'
import { initFirebaseRemoteConfig } from 'firebase-utils'
import { getCookieValue, setCookie } from './util/Cookies'

initFirebaseRemoteConfig()

// Explanation: were currently conducting an A/B test between:
//   A: the old, 3-step layout of sign-up (with an all-in-one checkout page collecting all lead data and billing info)
//   B: a new, 4-step layout of sign-up (where step 4 collects only the CC and billing address)
//
//   For users entering sign-up for the first time with ?split_checkout=true, they will be flagged with a cookie that will ensure
//   they continuosly use the new layout (event when leaving/returning to sign-up)

const splitCheckoutCookieName = 'split_checkout'

const useNewLayout = (new URLSearchParams(window.location.search)).get('split_checkout')?.toLowerCase() === 'true' ||
                        getCookieValue(splitCheckoutCookieName) === 'true'

if (useNewLayout) {
    // Set a cookie so we remember that this user should continue using the new layout on exiting + returning to sign-up
    setCookie(splitCheckoutCookieName, 'true')
}

ReactDOM.render(
    useNewLayout ? <App/> : <OldApp />,
    document.getElementById('app-container')
)
