import React from 'react'
import TextFieldMUI, { TextFieldProps } from 'text-field-mui'
import { createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() =>
    createStyles({
        textField: {
            textAlign: 'left',
            height: '44px'
        }
    })
)

type SignUpTextFieldProps = TextFieldProps

/**
 * Customized text field, for usage specifically throughout the sign up page.
 */
const TextField = ({ InputProps, ...otherProps } : SignUpTextFieldProps): JSX.Element => {
    const classes = useStyles()

    return (
        <TextFieldMUI
            className={classes.textField}
            fullWidth
            InputProps={{
                endAdornment: null,
                ...InputProps
            }}
            {...otherProps} />
    )
}

export default TextField
