interface reportAPICallParams {
    apiURL: string,
    requestPayload: unknown,
    responseText: string,
    textStatus: string,
    statusCode: number,
    isSuccess: boolean,
    errorMessage?: string
}

/**
 *
 */
export const reportAPICall = ({ apiURL, requestPayload, responseText, textStatus, statusCode, isSuccess, errorMessage }: reportAPICallParams) => {
    if (!('wzTag' in window)) {
        return // This API won't be available in some environments like dev environments
    }

    const url = new URL(apiURL)
    const api = `${url.host}${url.pathname}`
    const goalPayload = {
        goal_key: `API Request to ${api}`, /* REQUIRED */ /* eslint-disable-line @typescript-eslint/naming-convention */
        isSuccess: isSuccess, /* REQUIRED */
        errorMessage: errorMessage, /* NOT REQUIRED if isSuccess=true */
        statusCode: statusCode,
        customData: [
            {
                key: 'payload',
                value: requestPayload
            },
            {
                key: 'textStatus',
                value: textStatus
            },
            {
                key: 'responseText',
                value: responseText
            }
        ]
    }

    window.wzTag('goal', {
        type: 'goal',
        payloads: {
            type: 'goal',
            payloads: goalPayload
        }
    })
}
