import React, { useCallback, useEffect, useState } from 'react'
import * as API from '../../../util/API'
import * as GTM from '../../../util/GTM'
import Alert, { Color as AlertColor } from '../../../design-components/Alert'
import Link from '../../../design-components/Link'
import NumberSearchResults from '../NumberSearchResults'
import { PhoneNumberData } from '../../../types'
import { ChevronForwardIcon } from 'svg-icons/src'
import { createStyles, makeStyles } from '@material-ui/core'
import { mergePhoneNumbers } from '../../../util/PhoneNumberHelpers'
import { useHistory } from 'react-router-dom'
import Typography from 'typography'

const useStyles = makeStyles(() =>
    createStyles({
        chooseCodeLink: {
            marginBottom: '20px',
            fontSize: '12px',
            fontFamily: 'Montserrat',
            fontWeight: 600,
            lineHeight: '15px',
            color: '#29921B'
        }
    })
)

/**
 * Fetches and displays numbers for area code(s) closest to the user's location.
 * The backend guesses their location by their IP address
 */
const ShowLocalNumbers = () : JSX.Element => {
    const [loading, setLoading] = useState<boolean>(true)
    const [numbers, setNumbers] = useState<PhoneNumberData[]>([])
    const [error, setError] = useState(null)

    const history = useHistory()

    const classes = useStyles()

    const search = useCallback((loadMore = false) => {
        setLoading(true)
        if (!loadMore) {
            setNumbers([])
        }

        API.getLocalNumbers().then(response => {
            const updateNumbers = mergePhoneNumbers(loadMore ? numbers : [], response?.data.numbers)
            setNumbers(updateNumbers)
            setError(null)

            const autoDetectedAreacode = response?.data.numbers.length ? response?.data.numbers[0].phone_number.slice(2, 5) : null
            if (autoDetectedAreacode && !loadMore) {
                GTM.autoDetectedAreacode(autoDetectedAreacode)
            }
        }, error => setError(error)).then(() => setLoading(false))
    }, [numbers])

    useEffect(() => {
        search()
    }, [])

    const onChooseAreaCode = (event: React.MouseEventHandler<HTMLAnchorElement>) => {
        event.preventDefault()
        history.push('/pick-a-number/local/choose-area-code')
    }

    const chooseAreaCodeLink = () => (
        <Link data-testid="choose-area-code-link" onClick={onChooseAreaCode} className={classes.chooseCodeLink}>
            Choose a different area code<ChevronForwardIcon />
        </Link>
    )

    return (
        <>
            {error &&
                <Alert
                    showIcon={true}
                    color={AlertColor.ERROR}
                    content={<>An error occured while trying to load search results</>}
                />
            }

            <NumberSearchResults
                numbers={numbers}
                zeroNumbersMessage={
                    <>
                        <Typography variant="body2" paragraph={true}>
                            No numbers were found within your area
                        </Typography>

                        {chooseAreaCodeLink()}
                    </>
                }
                renderAfterRecommendedNumber={chooseAreaCodeLink}
                loading={loading}
                loadMoreNumbers={() => search(true)}
            />
        </>
    )
}

export default ShowLocalNumbers
