import React from 'react'
import './styles/App.css'
import AppRoutes from './AppRoutes'
import { ThemeProvider } from '@material-ui/styles'
import { theme } from 'get-theme'
import {
    BrowserRouter
} from 'react-router-dom'
import LeadProvider from './LeadProvider'
import ErrorCatcher from 'error-catcher'
import SomethingWentWrong from './SomethingWentWrong'
import OrderSummaryProvider from './steps/checkout/order-summary/OrderSummaryProvider'

/**
 * Highest-level component
 */
const App = () : JSX.Element => {
    return (
        <ErrorCatcher theme={theme} fallbackRender={SomethingWentWrong}>
            <ThemeProvider theme={theme}>
                <BrowserRouter> {/* useHistory() must be performed under this */}
                    <LeadProvider>
                        <OrderSummaryProvider>
                            <AppRoutes />
                        </OrderSummaryProvider>
                    </LeadProvider>
                </BrowserRouter>
            </ThemeProvider>
        </ErrorCatcher>
    )
}

export default App
