import React from 'react'
import { Bill } from '../../../types'

/**
 * Value to be passed/recieved by the order summary context
 */
export interface OrderSummaryContextValue {
    loading: boolean;
    bill: null | Bill;
    error: null | Error;
}

const orderSummaryContext = React.createContext<OrderSummaryContextValue>({
    loading: false,
    bill: null,
    error: null
})

export default orderSummaryContext
