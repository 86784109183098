import React, { useContext, useEffect, useState, useCallback } from 'react'
import orderSummaryContext from './OrderSummaryContext'
import { debounce } from 'debounce'
import { Bill } from '../../../types'
import LeadContext from '../../../LeadContext'
import * as GTM from '../../../util/GTM'
import * as API from '../../../util/API'

/**
 * Fetches (and re-fetches as needed) the order summary.
 *
 * Implemented as a high-level component so the order summary is persisted,
 * even when moving between various steps of sign-up.
 */
const OrderSummaryProvider = ({ children } : { children: React.ReactNode }): JSX.Element => {
    const { leadParams, clearCart, validatedZipCode } = useContext(LeadContext)

    const [loading, setLoading] = useState<boolean>(true)
    const [bill, setBill] = useState<null | Bill>(null)
    const [error, setError] = useState<null | Error>(null)

    const fetchBill = useCallback(
        debounce((coupon: string, period: number) => {
            console.log('==> fetchBill (debounced): args, ', coupon, period)
            API.getSummary(coupon, period).then(bill => {
                setBill(bill)
                setError(null)
                GTM.cartCalculated(bill, bill.payment_period)
            }, error => {
                setBill(null)
                if (error instanceof API.CartEmptyError) {
                    clearCart()
                }
                setError(error)
                GTM.transactionError(error, 'OrderSummary failed to load')
            }).then(() => setLoading(false))
        }, 1500),
        [])

    const reservationStatus = leadParams.cart.map(n => n.reservation_token).join(',')
    useEffect(() => {
        // Wait till the user has reserved a number, before fetching the bill as the bill
        // is heavily influenced by the number choosen.
        if (leadParams.cart.length > 0) {
            setLoading(true)
            fetchBill(leadParams.coupon_code, leadParams.payment_period)
        }
    }, [reservationStatus, leadParams.coupon_code, leadParams.payment_period, validatedZipCode])

    useEffect(() => {
        if (bill?.coupon_code === 'error') {
            GTM.couponRejected(bill.coupon_code_attempted)
        } else if (bill?.coupon_code) {
            GTM.couponApplied(bill.coupon_code)
        }
    }, [bill?.coupon_code, bill?.coupon_code_attempted])

    useEffect(() => {
        if (bill?.payment_period) {
            GTM.paymentPeriodSet(parseInt(bill.payment_period))
        }
    }, [bill?.payment_period])

    return (
        <orderSummaryContext.Provider
            value={{
                loading,
                bill,
                error
            }}
        >
            {children}
        </orderSummaryContext.Provider>
    )
}

export default OrderSummaryProvider
