import React, { useEffect, useState } from 'react'
import * as API from '../../../util/API'
import * as GTM from '../../../util/GTM'
import Alert, { Color as AlertColor } from '../../../design-components/Alert'
import Spinner from 'spinner'
import { createStyles, makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import Link from '../../../design-components/Link'
import { ChevronForwardIcon } from 'svg-icons/src'
import Typography from 'typography'

const useStyles = makeStyles(theme =>
    createStyles({
        description: {
            maxWidth: '766px',
            marginTop: '20px',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '30px'
        },
        heading: {
            fontSize: '20px',
            lineHeight: '28px',
            letterSpacing: '-0.1px',
            marginBottom: '20px'
        },
        tempNumber: {
            width: '210px',
            height: '60px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '2px',
            background: 'rgba(192, 225, 234, 0.75)',
            fontSize: '16px',
            lineHeight: '26px',
            textAlign: 'center',
            letterSpacing: '-0.1px',
            color: theme.palette.info.dark,
            margin: '0 auto'
        },
        footNote: {
            marginTop: '30px',
            fontSize: '12px',
            lineHeight: '19px',
            marginBottom: '32px',
            color: theme.palette.text.primary,
            maxWidth: '684px',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    })
)

/**
 * The custom number pick-a-number page that allows the user to search for numbers containing a string of text
 */
const TransferNumber = () : JSX.Element => {
    const classes = useStyles()

    const history = useHistory()

    const [loading, setLoading] = useState(true)
    const [transferInfo, setTransferInfo] = useState(null)
    const [error, setError] = useState(null)

    const getTransferNumber = async () => {
        setLoading(true)
        try {
            const response = await API.getTransferNumber()
            const { data } = response
            setTransferInfo(data)

            const number = `+1${data.temp_number.replace(/-/g, '')}`
            GTM.reserveNumber(number, 0, false)
        } catch (error) {
            setError(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        getTransferNumber()
        GTM.pickNumberType('transfer')
    }, [])

    const onNextClicked = event => {
        event.preventDefault()
        history.push('/checkout')
    }

    if (loading) {
        return (
            <Spinner
                data-testid="loading-spinner"
                size="big" />
        )
    }

    if (error) {
        return (
            <Alert
                showIcon={true}
                color={AlertColor.ERROR}
                content={error.message}
            />
        )
    }

    return (
        <>
            <Typography remoteConfigID="signup_transfer_number_description" variant='body2' className={classes.description} />

            <h5 className={classes.heading}>
                {transferInfo.step_label}
            </h5>

            <div data-testid="transfer-number-temp-number" className={classes.tempNumber}>
                {transferInfo.temp_number}
            </div>

            <Typography remoteConfigID="signup_transfer_number_footer" variant='body2' color="textSecondary" className={classes.footNote} />

            <Link data-testid="tranfer-number-continue-link" onClick={onNextClicked}>Continue<ChevronForwardIcon /></Link>
        </>
    )
}

export default TransferNumber
