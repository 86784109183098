import React from 'react'
import ReserveNumberContextProvider from './ReserveNumberContextProvider'
import {
    Switch,
    Route
} from 'react-router-dom'
import TollFree from './toll-free/TollFree'
import LocalNumber from './local-number/LocalNumber'
import CustomNumber from './custom-number/CustomNumber'
import TransferNumber from './transfer-number/TransferNumber'
import NumberOptions from './NumberOptions'

const basePath = '/pick-a-number'

/**
 * Pick a number step of sign-up
 */
const PickANumber = () : JSX.Element => {
    return (
        <ReserveNumberContextProvider>
            <>
                <Switch>
                    <Route exact path={basePath}>
                        <NumberOptions />
                    </Route>
                    <Route path={`${basePath}/toll-free`}>
                        <TollFree />
                    </Route>
                    <Route path={`${basePath}/local`}>
                        <LocalNumber />
                    </Route>
                    <Route path={`${basePath}/custom`}>
                        <CustomNumber />
                    </Route>
                    <Route path={`${basePath}/transfer`}>
                        <TransferNumber />
                    </Route>
                </Switch>
            </>
        </ReserveNumberContextProvider>
    )
}

export default PickANumber
