import React, { useRef } from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import ProgressBarFunctionalComponent from 'progress-bar'

const useStyles = makeStyles(theme =>
    createStyles({
        progressBar: {
            borderRadius: 6.5,
            background: 'rgba(63, 174, 41, 0.25)'
        },
        innerBar: {
            height: '100%',
            width: 0,
            background: '#3FAE29',
            borderRadius: 6.5
        }
    })
)

interface ProgressBarProps {
    className: string
}

/**
 * Customized progress bar for use within the sign-up app
 */
const ProgressBar = ({ className }: ProgressBarProps) => {
    const classes = useStyles()
    const innerBarRef = useRef()

    const onProgressChange = width => {
        const innerBar = innerBarRef?.current
        if (innerBar) innerBar.style.width = `${width}%`
    }

    return (
        <div className={`${classes.progressBar} ${className}`}>
            <ProgressBarFunctionalComponent
                progressThresholds={{ creation: { min: 0, max: 100 } }}
                intervalSpeeds={{ SLOW: 200 }}
                currentStep='creation'
                lastStep='end'
                onProgress={onProgressChange}
                showSlider={true}
            >
                <div ref={innerBarRef} className={classes.innerBar} />
            </ProgressBarFunctionalComponent>
        </div>
    )
}

export default ProgressBar
