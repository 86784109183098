import React, { useEffect, useRef, useState } from 'react'
import OrderSummary from './order-summary/OrderSummary'
import BusinessInfoForm from './BusinessInfoForm'
import Button from 'button'
import * as API from '../../../src/util/API'
import * as GTM from '../../../src/util/GTM'
import CreatingYourAccount from './CreatingYourAccount'
import Alert, { Color as AlertColor } from '../../design-components/Alert'
import { AccountDetails, Lead, LeadParams } from '../../types'
import { injectStripe, Stripe } from 'react-stripe-elements'
import ReCAPTCHA from 'react-google-recaptcha'
import { ValidationError, validateLead } from '../../util/Validations'
import { createStyles, makeStyles } from '@material-ui/core'
import Typography from 'typography'
import { debounce } from 'debounce'
import PageTitle from '../../PageTitle'
import AccountCreated from './AccountCreated'
import Spinner from 'spinner'
import { formatPhoneNumber } from 'phone-numbers'
import CheckoutBadges from './CheckoutBadges'

const useStyles = makeStyles(theme =>
    createStyles({
        form: {
            margin: '0 auto',
            maxWidth: '810px'
        },
        columns: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'center',
            [theme.breakpoints.up(1024)]: {
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'start'
            }
        },
        leftColumn: {
            [theme.breakpoints.up(1024)]: {
                maxWidth: '500px'
            }
        },
        rightColumn: {
            marginTop: '60px',
            [theme.breakpoints.up(1024)]: {
                maxWidth: '250px',
                marginTop: '0px',
                marginLeft: '60px'
            }
        },
        termsAndConditions: {
            marginTop: '20px',
            fontSize: '11px !important',
            lineHeight: '16px !important',
            '& a': {
                color: '#6E7A82',
                fontWeight: '500',
                textDecoration: 'underline',
                fontSize: '10.5px',
                lineHeight: '16px',
                letterSpacing: '0.1px'
            }
        },
        submitContainer: {
            marginTop: '30px',
            marginBottom: '20px'
        },
        submitButton: {
            width: '100%',
            height: '32px',
            fontSize: '12px',
            fontFamily: 'Montserrat-semi-bold',
            lineheight: '15px',
            paddingTop: '8.5px',
            paddingBottom: '8.5px',
            '& svg': {
                height: '20px',
                width: '20px'
            },
            [theme.breakpoints.down('sm')]: {
                height: '40px',
                fontSize: '14px'
            }
        }
    })
)

interface CheckoutProps {
    stripe: Stripe,
    leadParams: LeadParams,
    setShowStepper: (showStepper: boolean) => void,
    setDisableStepper: (disableStepper: boolean) => void
}

/**
 * Checkout step of sign-up
 */
const Checkout = ({ stripe, leadParams, setShowStepper, setDisableStepper } : CheckoutProps) : JSX.Element => {
    const classes = useStyles()

    const [error, setError] = useState<Error | null>(null)
    const [processing, setProcessing] = useState(false)
    const [isCreatingAccount, setIsCreatingAccount] = useState<boolean>(false)
    const [accountDetails, setAccountDetails] = useState<AccountDetails | null>(null)

    // Initialize based on saved lead data from DB.
    // Optimization: for new leads, initially select "US" as most be are from the US
    const [businessInfo, setBusinessInfo] = useState<Lead>({
        company_name: leadParams.company_name, // eslint-disable-line @typescript-eslint/naming-convention
        first_name: leadParams.first_name, // eslint-disable-line @typescript-eslint/naming-convention
        last_name: leadParams.last_name, // eslint-disable-line @typescript-eslint/naming-convention
        email: leadParams.email,
        personal_phone_number: formatPhoneNumber(leadParams.personal_phone_number), // eslint-disable-line @typescript-eslint/naming-convention
        street: leadParams.street,
        city: leadParams.city,
        state: leadParams.state,
        billing_zip_code: leadParams.billing_zip_code, // eslint-disable-line @typescript-eslint/naming-convention
        country: leadParams.billing_country_code === 'CA' ? 'Canada' : 'United States', // eslint-disable-line @typescript-eslint/naming-convention
        billing_country_code: leadParams.billing_country_code || 'US' // eslint-disable-line @typescript-eslint/naming-convention
    })

    const [validatedZipCode, setValidatedZipCode] = useState<string>(leadParams.billing_zip_code || '')
    const [isOrderSummaryLoaded, setIsOrderSummaryLoaded] = useState<boolean>(false)
    const [couponIsInvalid, setCouponIsInvalid] = useState<boolean>(false)
    const [checkingZip, setCheckingZip] = useState<boolean>(false)
    const [isZipValid, setIsZipValid] = useState<boolean>(true)

    const [skipCreditCardEntry, setSkipCreditCardEntry] = useState<boolean>(false)

    const [mfaEnabled] = useState<boolean>(leadParams.pdc_enable_mfa)

    const [mfaURL, setMFAURL] = useState<null | string>(null)
    const [loadingMFA, setLoadingMFA] = useState<boolean>(false)

    const recaptchaRef = useRef()

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search)
        if (searchParams.get('returned_from_mfa')) {
            console.info('MFA: User has returned from the MFA app, starting the account creation process...')
            createAccount()
        }
    }, [])

    const onSubmit = async event => {
        event.preventDefault()

        if (processing) {
            return // Prevent submitting again by pressing enter, etc.
        }

        setError(null) // clear any previous error
        setProcessing(true)

        // Check the pdc_enable_mfa cookie (acting as a feature flag), then perform additional steps for Multi-Factor Authentication (MFA) if needed
        let mfaReturnURL = null
        if (mfaEnabled) {
            const currentParams = new URLSearchParams(window.location.search)
            const returnURLParams = new URLSearchParams({ returned_from_mfa: 'true', session_token: currentParams.get('session_token') }) // eslint-disable-line @typescript-eslint/naming-convention
            mfaReturnURL = `https://${window.location.host}/checkout?${returnURLParams.toString()}`
        }

        const tasks = [
            { fn: () => validateLead(businessInfo), description: 'validating lead data' },
            { fn: () => saveLead(businessInfo, mfaReturnURL), description: 'saving lead data' },
            { fn: () => savePaymentToken(), description: 'payment information processing' },
            { fn: () => verifyHumanUser(), description: 'human verification' }
        ]
        try {
            for (const task of tasks) {
                await task.fn().catch(error => {
                    if (error instanceof ValidationError) {
                        GTM.validationError(error.message, error.errorCode)
                    } else {
                        GTM.checkoutError(error.message, task.description)
                    }
                    throw error
                })
            }
        } catch (error) {
            onError(error)
        }
    }

    const onError = (error: Error) => {
        console.info('Submission error: ', error)
        setError(error)
        setProcessing(false)
        setIsCreatingAccount(false)
        window.scrollTo(0, 0)
    }

    const saveLead = async (params: Lead, mfaReturnURL: string | null) => {
        const userQuantities = {
            basic_user_qty: leadParams.basic_user_qty, /* eslint-disable-line @typescript-eslint/naming-convention */
            plus_user_qty: leadParams.plus_user_qty, /* eslint-disable-line @typescript-eslint/naming-convention */
            pro_user_qty: leadParams.pro_user_qty /* eslint-disable-line @typescript-eslint/naming-convention */
        }
        if (mfaReturnURL) {
            console.info('MFA: saving (final) lead data AND starting MFA process...')
        }
        return await API.saveLead(params, userQuantities, mfaReturnURL).then(response => {
            if (response?.data?.skip_mfa) {
                // The backend will exempt the front-end from having to go through MFA in some cases such as being on a trusted IP address, etc.
                console.info('MFA: skipping MFA setup, as backend has signaled to skip_mfa.')
                GTM.mfaExempt(response?.data.details)
                return
            }
            if (mfaReturnURL) {
                const mfaAppURL = response.data.mfa_session.mfa_app_url
                console.info('MFA: received mfa_app_url: ', mfaAppURL)
                setMFAURL(mfaAppURL)
            }
        })
    }

    const savePaymentToken = async () => {
        if (skipCreditCardEntry) {
            return
        }

        const result = await stripe.createToken({ type: 'card' })

        if (result.error) {
            throw result.error
        } else {
            const stripePaymentToken = result.token.id
            await API.createPaymentToken(stripePaymentToken)
        }
    }

    // Starts the process of google recaptcha verifying the user is a human
    const verifyHumanUser = async () => {
        // Intentionally stop the "processing" state undisabling the UI including the submit button,
        // so that if the user dismisses the challenge modal (eg. clicking outside of it) they can restart the process by submitting again.
        setProcessing(false)

        recaptchaRef.current.execute()

        GTM.captchaStarted()
    }

    // JS callback done when recaptcha has finished verify the user is a human
    const onRecaptchaChange = async (recaptchaResponseToken: string) => {
        console.log(`onRecaptchaChange: token: ${recaptchaResponseToken}`)

        setProcessing(true)

        GTM.captchaSucceeded()

        const onRecaptchaError = () => {
            throw new Error('There was a problem verifying you are a human, please refresh the page and try again')
        }

        const response = await API.verifyRecaptcha(recaptchaResponseToken).catch(onRecaptchaError)

        if (response?.data?.success) {
            await goToMFA()
        } else {
            onRecaptchaError()
        }
    }

    const onRecaptchaExpired = () => {
        console.info('ReCAPTCHA Expired')
        GTM.captchaExpired()
        onError(new Error('Your ReCAPTCHA session used to verify that your a human has expired, please try again'))
    }

    const onRecaptchaError = (error: Error) => {
        console.error(`ReCAPTCHA Error: ${error.name} ${error.message}`)
        GTM.captchaError(error)
        onError(new Error('An error occured while trying to verify that your a human, please try again'))
    }

    const goToMFA = () => {
        if (mfaURL) {
            // User will need to go to the MFA app, then will later be returned to the
            // checkout page with ?returned_from_mfa=true, which will then resume the checkout process
            console.info('MFA: Navigating user to MFA App URL: ', mfaURL)
            window.location = mfaURL
            setLoadingMFA(true)
        } else {
            console.info('MFA: MFA App URL not provided, proceeding with normal account creation process...')
            createAccount()
        }
    }

    const createAccount = async () => {
        setIsCreatingAccount(true)
        try {
            const accountDetails = await API.createAccount()
            setAccountDetails(accountDetails)
        } catch (error) {
            onError(error)
            GTM.transactionError(error, 'account creation')
        }
    }

    const checkZipCode = async () : Promise<boolean> => {
        setCheckingZip(true)
        let isValid = false
        try {
            await API.checkZipCode(businessInfo.billing_zip_code)
            isValid = true
        } catch (error) {
            console.info('zip code validation error: ', error)
        }
        setIsZipValid(isValid)
        setCheckingZip(false)
        return isValid
    }

    // Debounce this so rapid changes in address info, don't overly aggressivley trigger
    // requests to save lead info and re-fetch the order summary
    const handleAddressChange = debounce(async (zipCode: string) => {
        const isZipValid = await checkZipCode()

        try {
            await saveLead(businessInfo)
        } catch (error) {
            console.error('failed to save lead, error: ', error)
            return
        }

        if (isZipValid) {
            // Once a valid zip code has been saved, we update this state which will trigger re-fetching
            // the order summary with updated taxes & fees
            setValidatedZipCode(zipCode)
        }
    }, 200)

    useEffect(() => {
        const zipCode = businessInfo.billing_zip_code || ''
        const requiredLength = businessInfo.billing_country_code === 'CA' ? 7 : 5
        if (businessInfo.billing_country_code && businessInfo.state && zipCode.length >= requiredLength) {
            handleAddressChange(zipCode)
        }
    }, [businessInfo.billing_country_code, businessInfo.state, businessInfo.billing_zip_code])

    useEffect(() => {
        setDisableStepper(processing || isCreatingAccount)
    }, [processing, isCreatingAccount])

    useEffect(() => {
        // For UX reasons we're hiding the Stepper when the account is created,
        // as there is no going back to previous steps at this point
        setShowStepper(!accountDetails)
    }, [accountDetails])

    if (accountDetails) return <AccountCreated accountDetails={accountDetails}/>
    if (isCreatingAccount) return <CreatingYourAccount />

    if (loadingMFA) {
        return <Spinner />
    }

    return (
        <>
            <PageTitle>
                Complete your order & create your account
            </PageTitle>

            <form id="checkout-form" name="checkout-form" className={classes.form} onSubmit={onSubmit}>
                {error &&
                    <Alert
                        data-testid="error-alert"
                        showIcon={true}
                        color={AlertColor.ERROR}
                        content={error.message}
                    />
                }

                <div className={classes.columns}>
                    <div className={classes.leftColumn}>
                        <BusinessInfoForm
                            businessInfo={businessInfo}
                            setBusinessInfo={setBusinessInfo}
                            checkingZip={checkingZip}
                            isZipValid={isZipValid}
                            disabled={processing}
                            skipCreditCardEntry={skipCreditCardEntry} />
                    </div>

                    <div className={classes.rightColumn}>
                        <OrderSummary
                            leadParams={leadParams}
                            validatedZipCode={validatedZipCode}
                            billingCountryCode={businessInfo.billing_country_code}
                            onLoadedChanged={isLoaded => setIsOrderSummaryLoaded(isLoaded)}
                            setCouponIsInvalid={couponIsInvalid => setCouponIsInvalid(couponIsInvalid)}
                            setSkipCreditCardEntry={setSkipCreditCardEntry}
                            disabled={processing}
                        />

                        <Typography variant="helperText" color="#6E7A82" display="block" align="left" className={classes.termsAndConditions}>
                            By clicking &quot;Create your account&quot;, you agree to the <a href={process.env.REACT_APP_TERMS_OF_SERVICE_URL} target="_blank" rel="noreferrer">Terms of Service</a> and <a href={process.env.REACT_APP_PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">Privacy Policy</a>. You will receive text and email messages with information and special offers. You may opt-out by texting &quot;STOP&quot;. Message frequency will vary. Message and data rates may apply.
                        </Typography>

                        <div className={classes.submitContainer}>
                            <Button data-testid="checkout-submit-button" className={classes.submitButton} disabled={processing || !isOrderSummaryLoaded || couponIsInvalid} type="submit" color={'primary'}>
                                {processing
                                    ? (
                                        <Spinner color="black" />
                                    )
                                    : (
                                        <>Create your account</>
                                    )}
                            </Button>
                        </div>

                        <CheckoutBadges />
                    </div>
                </div>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    badge="bottomleft"
                    sitekey="6LcEHXAUAAAAALeg1BlVWZNcvo6LCOf_mwa8rh1P"
                    onChange={onRecaptchaChange}
                    onError={onRecaptchaError}
                    onExpired={onRecaptchaExpired}
                />
            </form>
        </>
    )
}

export default injectStripe(Checkout)
