import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { CallIcon } from 'svg-icons/src'
import { formatPhoneNumber } from 'phone-numbers'
import Typography from 'typography'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '0px',
            height: '90px',
            background: '#FFFFFF',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15);',
            padding: '0px 30px',
            [theme.breakpoints.up('md')]: {
                padding: '0px 40px'
            },
            [theme.breakpoints.up('lg')]: {
                height: 'unset',
                padding: '0px 50px',
                marginTop: '60px',
                marginBottom: '40px',
                boxShadow: 'unset'
            }
        },
        logo: {
            content: 'url("/phonecom_logo_mobile.png")',
            [theme.breakpoints.up('lg')]: {
                content: 'url("/phonecom_logo_desktop.png")'
            }
        },
        salesInfo: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.up(600)]: {
                flexDirection: 'row',
                alignItems: 'center'
            },
            [theme.breakpoints.up('lg')]: {
                fontSize: '16px'
            }
        },
        salesText: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            '& svg': {
                marginRight: '4.61px',
                width: '16px',
                height: '16px'
            }
        },
        salesNumber: {
            [theme.breakpoints.up(600)]: {
                marginLeft: '8px'
            },
            [theme.breakpoints.up('lg')]: {
                fontWeight: 500
            }
        }
    })
)

/**
 * Header component displayed across top of all sign-up pages, above section.content.
 */
const Header = () : JSX.Element => {
    const classes = useStyles()

    const salesNumber = process.env.REACT_APP_SALES_TEL_NUMBER?.replace(/\D/g, '')

    return (
        <header className={classes.header}>
            <a href="https://www.phone.com" rel="noreferrer">
                <img data-testid="header-logo"
                    className={classes.logo}
                    alt="Phone.com"
                />
            </a>

            <div className={classes.salesInfo}>
                <Typography variant="subtitle1" className={classes.salesText}>
                    <CallIcon />
                    SALES
                </Typography>
                <a data-testid="header-sales-number" className={classes.salesNumber} href={`tel:${salesNumber}`}>
                    <Typography variant="subtitle1" color="primary">
                        {formatPhoneNumber(salesNumber)}
                    </Typography>
                </a>
            </div>
        </header>
    )
}

export default Header
