import React, { useState, useEffect } from 'react'
import { Stepper, Variant as StepperVariant } from 'stepper'
import ChooseAPlan from './choose-a-plan/ChooseAPlan'
import PickANumber from './pick-a-number/PickANumber'
import CheckoutPage from './checkout/CheckoutPage'
import { useHistory } from 'react-router-dom'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/core'
import * as GTM from '../util/GTM'

const useStyles = makeStyles(theme =>
    createStyles({
        stepper: {
            background: 'transparent',
            marginBottom: '15px',
            [theme.breakpoints.up('md')]: {
                margin: '50px auto',
                maxWidth: '272px'
            },
            '& .stepper-nav-button:last-of-type': { // Hide next button
                visibility: 'hidden'
            },
            '& .stepper-nav-button': {
                width: 'unset' // tightly bounds the nav buttons, so they can be flush against edges of container
            },
            padding: 0
        },
        currentStep: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
        }
    })
)

const signUpSteps = [
    {
        'data-testid': 'choose-a-plan-step',
        id: 'chooose-a-plan',
        title: 'Choose a Plan',
        done: true,
        component: ChooseAPlan
    },
    {
        'data-testid': 'pick-a-number-step',
        id: 'pick-a-number',
        title: 'Pick a Number',
        component: PickANumber
    },
    {
        'data-testid': 'checkout-step',
        id: 'checkout',
        title: 'Review & checkout',
        component: CheckoutPage
    }
]

interface StepsProps {
    currentStepId: string
}

/**
 * Renders the current step within the sequence of steps that make up the sign-up flow.
 */
const Steps = ({ currentStepId } : StepsProps) : JSX.Element => {
    const classes = useStyles()

    const theme = useTheme()
    const isSmallView = !useMediaQuery(theme.breakpoints.up('md'))

    const history = useHistory()

    const [showStepper, setShowStepper] = useState<boolean>(true)
    const [disableStepper, setDisableStepper] = useState<boolean>(false)

    const stepperSteps = signUpSteps.map(step => ({ ...step, active: step.id === currentStepId }))
    const CurrentStep = signUpSteps.find(step => step.id === currentStepId)?.component

    useEffect(() => {
        if (currentStepId) {
            GTM.reportStepChange(currentStepId)
        }
    }, [currentStepId])

    const goToStep = (stepId : string) => {
        history.push(`/${stepId}`)
    }

    const currentStepIndex = signUpSteps.findIndex(step => step.id === currentStepId)

    const goBack = () => {
        history.goBack()
    }

    if (!currentStepId) {
        goToStep(signUpSteps[0].id)
        return <></>
    }

    const stepInfo = stepperSteps.map((step, idx) => ({ ...step, done: idx < currentStepIndex }))

    return (
        <>
            {showStepper &&
                <Stepper
                    smallView={isSmallView}
                    className={classes.stepper}
                    variant={StepperVariant.HORIZONTAL}
                    steps={stepInfo}
                    onBackClick={goBack}
                    onStepClick={stepId => goToStep(stepId)}
                    disabled={disableStepper}
                />
            }
            <div className={classes.currentStep}>
                <CurrentStep setShowStepper={setShowStepper} setDisableStepper={setDisableStepper} />
            </div>
        </>
    )
}

export default Steps
