import React, { useState } from 'react'
import { Bill } from '../../../types'
import Item from './Item'
import { createStyles, makeStyles, ClickAwayListener } from '@material-ui/core'
import { InfotipTooltipIcon } from 'svg-icons/src'
import Typography from 'typography'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles(() =>
    createStyles({
        taxesAndFeesHeader: {
            display: 'flex',
            justifyContent: 'space-between',
            '& svg': {
                width: '15px',
                height: '15px',
                marginBottom: '2px'
            }
        },
        tooltip: {
            maxWidth: '250px',
            background: '#364047',
            padding: '14px 16px'
        },
        helperText: {
            width: '149px'
        }
    })
)

interface TaxesAndFeesProps {
    bill: Bill
}

/**
 * Listing of taxes and fees, as part of the OrderSummary
 */
const TaxesAndFees = ({ bill } : TaxesAndFeesProps) : JSX.Element | null => {
    const classes = useStyles()
    const section = bill.details.find(s => s.section === 'fees_and_taxes')

    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)
    const [mouseOver, setMouseOver] = useState<boolean>(false)

    return (
        <>
            <ClickAwayListener onClickAway={() => setTooltipOpen(false)}>
                <Tooltip
                    open={mouseOver || tooltipOpen}
                    onClose={() => setTooltipOpen(false)}
                    classes={{ tooltip: classes.tooltip }}
                    disableHoverListener={tooltipOpen}
                    interactive
                    title={
                        <>
                            <Typography remoteConfigID="signup_order_summary_taxes_tooltip" variant="helperText" />
                            &nbsp;
                            <a target="_blank" href="https://support.phone.com/what-taxes-and-fees-are-billed-to-my-account" rel="noreferrer">
                                Learn more
                            </a>
                        </>
                    }
                    placement='top'>
                    <div
                        className={classes.taxesAndFeesHeader}

                        onClick={() => setTooltipOpen(!tooltipOpen)}>
                        <Typography variant='subtitle3' align="left" noWrap>
                            Taxes & fees
                            &nbsp;
                            <InfotipTooltipIcon
                                onMouseOver={() => setMouseOver(true)}
                                onMouseLeave={() => setMouseOver(false)}
                                width="15px"
                                height="15px"
                            />
                        </Typography>

                        {!section && (
                            <Typography
                                remoteConfigID="signup_order_summary_taxes_shown_later"
                                className={classes.helperText}
                                variant="helperText"
                                align="right"
                                color="#6E7A82"
                            />
                        )}
                    </div>
                </Tooltip>
            </ClickAwayListener>

            {section?.charges.map((charge, idx) => (
                <Item key={idx} label={charge.name} cost={charge.cost} />
            ))}
        </>
    )
}

export default TaxesAndFees
