import React, { useEffect, useState } from 'react'
import * as API from '../../../util/API'
import Alert, { Color as AlertColor } from '../../../design-components/Alert'
import NumberSearchResults from '../NumberSearchResults'
import PageTitle from '../../../PageTitle'
import { mergePhoneNumbers } from '../../../util/PhoneNumberHelpers'
import Typography from 'typography'

/**
 * The toll-free number picker pages that allows the user to view a list of available toll-free numbers
 */
const TollFree = () : JSX.Element => {
    const [loading, setLoading] = useState(true)
    const [tollFreeInfo, setTollFreeInfo] = useState(null)
    const [error, setError] = useState(null)

    const loadData = async () => {
        setLoading(true)
        try {
            const response = await API.getTollFreeNumbers()
            const newTollFreeInfo = response?.data
            const updateNumbers = mergePhoneNumbers(tollFreeInfo?.numbers || [], newTollFreeInfo.numbers)
            newTollFreeInfo.numbers = updateNumbers
            setTollFreeInfo(newTollFreeInfo)
        } catch (error) {
            setError(error)
        }
        setLoading(false)
    }

    useEffect(() => {
        loadData()
    }, [])

    return (
        <>
            <PageTitle remoteConfigID="signup_toll_free_numbers_title" />

            {error &&
                <Alert
                    showIcon={true}
                    color={AlertColor.ERROR}
                    content={error.message}
                />
            }

            <NumberSearchResults
                numbers={tollFreeInfo?.numbers || []}
                zeroNumbersMessage={<Typography remoteConfigID="signup_toll_free_numbers_no_numbers" variant="body2" />}
                footer={<Typography remoteConfigID="signup_toll_free_numbers_footer" variant="caption" color="textSecondary" align="center" />}
                loading={loading}
                loadMoreNumbers={loadData}
            />
        </>
    )
}

export default TollFree
