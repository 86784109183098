import React, { useState, useEffect } from 'react'
import * as API from '../../../util/API'
import * as GTM from '../../../util/GTM'
import { Bill, LeadParams } from '../../../types'
import Spinner from 'spinner'
import Alert, { Color as AlertColor } from '../../../design-components/Alert'
import BillSections from './BillSections'
import { debounce } from 'debounce'
import Heading from '../../../Heading'
import { createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() =>
    createStyles({
        orderSummary: {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            marginBottom: '20px'
        }
    })
)

interface OrderSummaryProps {
    validatedZipCode: string,
    billingCountryCode: string,
    onLoadedChanged: (isLoaded: boolean) => void,
    setCouponIsInvalid: (couponIsInvalid: boolean) => void,
    leadParams: LeadParams,
    setSkipCreditCardEntry: (skip: boolean) => void,
    disabled?: boolean
}

/**
 * Dislays the state of your cart and the totals/prices associated with your order
 */
const OrderSummary = ({ validatedZipCode, billingCountryCode, onLoadedChanged, setCouponIsInvalid, leadParams, setSkipCreditCardEntry, disabled } : OrderSummaryProps) : JSX.Element => {
    const [loading, setLoading] = useState<boolean>(true)
    const [bill, setBill] = useState<Bill | null>(null)
    const [error, setError] = useState<Error | null>(null)
    const [couponCode, setCouponCode] = useState<string>(leadParams.coupon_code)
    const [paymentPeriod, setPaymentPeriod] = useState<number>(parseInt(leadParams.payment_period))

    const classes = useStyles()

    const fetchBill = debounce(() => {
        setLoading(true)
        API.getSummary(couponCode, paymentPeriod).then(bill => {
            setSkipCreditCardEntry(bill.skip_credit_card_entry)
            setBill(bill)
            setError(null)
            GTM.cartCalculated(bill, bill.payment_period)
        }, error => {
            setBill(null)
            setError(error)
            GTM.transactionError(error, 'OrderSummary failed to load')
        }).then(() => setLoading(false))
    }, 1000)

    // Auto fetch the summary/bill whenever the user tries a coupon, or changes the
    // billing period frequency. This is also done on mount
    useEffect(() => {
        fetchBill()
    }, [couponCode, paymentPeriod, validatedZipCode])

    useEffect(() => {
        onLoadedChanged(!loading && Boolean(bill))
    }, [bill, loading])

    useEffect(() => {
        setCouponIsInvalid(bill?.coupon_code === 'error')
    }, [bill?.coupon_code])

    useEffect(() => {
        if (bill?.coupon_code === 'error') {
            GTM.couponRejected(bill.coupon_code_attempted)
        } else if (bill?.coupon_code) {
            GTM.couponApplied(bill.coupon_code)
        }
    }, [bill?.coupon_code, bill?.coupon_code_attempted])

    useEffect(() => {
        if (bill?.payment_period) {
            GTM.paymentPeriodSet(parseInt(bill.payment_period))
        }
    }, [bill?.payment_period])

    return (
        <div className={classes.orderSummary}>
            <Heading remoteConfigID="signup_order_summary_heading" />

            {loading
                ? (
                    <Spinner data-testid="loading-spinner" />
                )
                : error
                    ? (
                        <Alert
                            data-testid="error-alert"
                            showIcon={true}
                            color={AlertColor.ERROR}
                            content={error.message}
                        />
                    )
                    : bill
                        ? (
                            <BillSections
                                bill={bill}
                                billingCountryCode={billingCountryCode}
                                setPaymentPeriod={setPaymentPeriod}
                                setCouponCode={setCouponCode}
                                disabled={disabled} />
                        )
                        : null}
        </div>
    )
}

export default OrderSummary
