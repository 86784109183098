import React from 'react'
import Button from 'button'
import { createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme =>
    createStyles({
        submitButton: {
            width: '100%',
            height: '32px',
            fontSize: '12px',
            fontFamily: 'Montserrat-semi-bold',
            lineheight: '15px',
            paddingTop: '8.5px',
            paddingBottom: '8.5px',
            '& svg': {
                height: '20px',
                width: '20px'
            },
            [theme.breakpoints.down('sm')]: {
                height: '40px',
                fontSize: '14px'
            }
        }
    })
)

interface SubmitButtonProps {
    children: React.ReactNode;
    disabled?: boolean;
}

/**
 * Large green submit button used to proceed to the next step / submit the order.
 */
const SubmitButton = ({ children, disabled }: SubmitButtonProps) : JSX.Element => {
    const classes = useStyles()

    return (
        <Button
            type="submit"
            data-testid="submit-button"
            className={classes.submitButton}
            disabled={disabled}
            color={'primary'}
        >
            {children}
        </Button>
    )
}

export default SubmitButton
