import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme =>
    createStyles({
        columns: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'start',
            alignItems: 'stretch',
            [theme.breakpoints.up(1024)]: {
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'start'
            }
        },
        leftColumn: {
            [theme.breakpoints.up(1024)]: {
                maxWidth: '500px'
            }
        },
        rightColumn: {
            marginTop: '60px',
            [theme.breakpoints.up(1024)]: {
                maxWidth: '250px',
                marginTop: '0px',
                marginLeft: '60px'
            }
        }
    })
)

/**
 * Re-usable order 2 column layout used by multiple steps of the sign-up process.
 */
const OrderLayout = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const classes = useStyles()
    return (
        <div className={classes.columns}>
            {children}
        </div>
    )
}

/**
 * Larger, left column within the OrderLayout
 */
export const OrderLayoutLeftColumn = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const classes = useStyles()
    return (
        <div className={classes.leftColumn}>
            {children}
        </div>
    )
}

/**
 * Smaller, right column within the OrderLayout.
 * This collapses belows the left column on small screens.
 */
export const OrderLayoutRightColumn = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const classes = useStyles()
    return (
        <div className={classes.rightColumn}>
            {children}
        </div>
    )
}

export default OrderLayout
