import React, { useEffect } from 'react'
import Spinner from 'spinner'
import { redirectToPricingPage } from '../../../src/util/API'

/**
 * Chose a plan step of sign-up.
 * This is actually exists over on the /pricing page so the user will be navigated there
 */
const ChooseAPlan = () : JSX.Element => {
    useEffect(() => {
        redirectToPricingPage()
    }, [])

    return (
        <Spinner />
    )
}

export default ChooseAPlan
