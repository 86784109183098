import React, { useState } from 'react'
import * as API from '../../util/API'
import * as GTM from '../../util/GTM'
import { ReserveNumberContext } from './ReserveNumberContext'
import { useHistory } from 'react-router-dom'
import { PhoneNumberData } from '../../types'
import { getCookieValue } from '../../util/Cookies'

interface ReserveNumberContextProviderProps {
    children: JSX.Element
}

/**
 * This context provides components throughout the pick-a-number step with access to state related to
 * reserving a number in order to purchase it.
 * This includes the status of the request, functions to reserve a number, and in the future numbers that
 * were found to be already taken.
 */
const ReserveNumberContextProvider = ({ children } : ReserveNumberContextProviderProps) : JSX.Element => {
    const [isReservingNumber, setIsReservingNumber] = useState<boolean>(false)
    const [choosenPhoneNumber, setChoosenPhoneNumber] = useState<PhoneNumberData | null>(null)
    const [numbersTaken, setNumbersTaken] = useState<PhoneNumberData[]>([])
    const [error, setError] = useState<Error | null>(null)
    const history = useHistory()

    const reserveNumber = (phoneNumberData: PhoneNumberData) => {
        setChoosenPhoneNumber(phoneNumberData)
        setIsReservingNumber(true)
        API.reserveNumber(phoneNumberData).then(() => {
            history.push(`/checkout?session_token=${getCookieValue('pdc_lead_session_token')}`)
        }, error => {
            if (error.message.includes('got to that number first')) {
                GTM.numberAlreadyTaken(phoneNumberData.phone_number)
                setNumbersTaken([...numbersTaken, phoneNumberData])
            } else {
                GTM.reserveNumberError(error)
            }
            setError(error)
        }).then(() => setIsReservingNumber(false))
    }

    return (
        <ReserveNumberContext.Provider value={{ reserveNumber, isReservingNumber, choosenPhoneNumber, error, numbersTaken }}>
            {children}
        </ReserveNumberContext.Provider>
    )
}

export default ReserveNumberContextProvider
