import React from 'react'
import GlobalStateSelector from 'state-selector'
import { createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() =>
    createStyles({
        label: {
            textAlign: 'left'
        }
    })
)

/**
 * Customized text field, for usage specifically throughout the sign up page.
 */
const StateSelector = ({ ...props }) => {
    const classes = useStyles()

    return (
        <GlobalStateSelector
            className={classes.label}
            fullWidth
            {...props} />
    )
}

export default StateSelector
