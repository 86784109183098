import React, { useContext } from 'react'
import { PhoneNumberData } from '../../types'
import PhoneNumber from './PhoneNumber'
import NumberList from './NumberList'
import { Divider, createStyles, makeStyles } from '@material-ui/core'
import Typography from 'typography'
import Link from '../../design-components/Link'
import { RefreshIcon } from 'svg-icons/src'
import Spinner from 'spinner'
import { ReserveNumberContext } from './ReserveNumberContext'

const useStyles = makeStyles(() =>
    createStyles({
        divider: {
            maxWidth: '440px',
            width: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
            marginBottom: '30px'
        },
        spacer: {
            marginTop: '10px'
        },
        moreNumbers: {
            marginTop: '15px'
        },
        moreNumberSpinner: {
            marginTop: '30px'
        },
        footer: {
            margin: '10px auto',
            maxWidth: '350px'
        }
    })
)

interface NumberSearchResultsProps {
    numbers: PhoneNumberData[],
    isVanityNumber?: boolean,
    zeroNumbersMessage?: React.ReactNode,
    footer?: React.ReactNode,
    noRecommendedNumber?: boolean,
    renderAfterRecommendedNumber?: (() => React.ReactNode | null),
    loading: boolean,
    loadMoreNumbers: () => void
}

/**
 * Displays a list of numbers returned from some search.
 * Recommends on of the numbers, and displays all other numbers below it
 */
const NumberSearchResults = ({
    numbers,
    isVanityNumber,
    noRecommendedNumber,
    renderAfterRecommendedNumber,
    zeroNumbersMessage,
    footer,
    loading,
    loadMoreNumbers
} : NumberSearchResultsProps) : JSX.Element => {
    const classes = useStyles()

    const { error } = useContext(ReserveNumberContext)

    if (!numbers.length) {
        return loading
            ? (
                <Spinner size="big" />
            )
            : (
                <p className={classes.noNumbers}>
                    {zeroNumbersMessage || 'No numbers found'}
                </p>
            )
    }

    const recommendedNumber = noRecommendedNumber ? null : numbers[0]
    const otherNumbers = numbers.filter(num => num.phone_number !== recommendedNumber?.phone_number)

    const onMoreNumbersClicked = event => {
        event.preventDefault()
        loadMoreNumbers()
    }

    return (
        <>
            {recommendedNumber &&
                <>
                    <Typography remoteConfigID='signup_number_search_results_recommended_number' variant='body1' align="center" />

                    <PhoneNumber
                        highlight={true}
                        phoneNumber={recommendedNumber}
                        isVanityNumber={isVanityNumber}
                    />

                    {renderAfterRecommendedNumber
                        ? (
                            renderAfterRecommendedNumber()
                        )
                        : (
                            <span className={classes.spacer} />
                        )}
                </>
            }

            {error && (otherNumbers.length === 0) &&
                <Typography color="error">
                    {error.message}
                </Typography>
            }

            {otherNumbers.length
                ? (
                    <>
                        <Divider className={classes.divider} />

                        {error
                            ? (
                                <Typography color="error">
                                    {error.message}
                                </Typography>
                            )
                            : (
                                <Typography remoteConfigID={recommendedNumber ? 'signup_number_search_results_or_choose' : 'signup_number_search_results_choose'} variant="body1" align="center" />
                            )}

                        {footer && (
                            <p className={classes.footer}>
                                {footer}
                            </p>
                        )}

                        <NumberList
                            numbers={otherNumbers}
                            isVanityNumber={isVanityNumber} />
                    </>
                )
                : null}

            {loading &&
                <Spinner size="big" className={classes.moreNumberSpinner} />
            }

            {(!loading && numbers.length >= 8) &&
                <Link onClick={onMoreNumbersClicked} className={classes.moreNumbers}>
                    More numbers <RefreshIcon />
                </Link>
            }
        </>
    )
}

export default NumberSearchResults
