import JSCookie from 'js-cookie'

interface Options {
    domain?: string,
    expires?: number
}

/**
 * Gets the value of the cookie with the given name
 */
export const getCookieValue = (name : string) => {
    return JSCookie.get(name)
}

/**
 * Sets a cookie to the given value
 */
export const setCookie = (name : string, value : string, options ?: Options) => {
    JSCookie.set(name, value, options || {})
}

/**
 * Deletes a cookie
 */
export const removeCookie = (name : string) => {
    JSCookie.remove(name)
}
