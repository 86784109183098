import gtmDataPush from 'gtm-events'
import { AccountDetails, Bill, BillSection, Charge, LeadParams, Lead } from '../types'
import { debounce } from 'debounce'
import { CreateAccountError } from './API'

const sendEvent = data => {
    // Note although the FS namespace is set via window['_fs_namespace'] that can be overridden during runtime
    gtmDataPush(data, { fullStory: { namespace: 'FS2' } })
}

/**
 * Event to be sent once during
 */
export const signUpStart = (leadParams: LeadParams): void => {
    const pdcLeadParams = {}
    Object.keys(leadParams).forEach(key => {
        pdcLeadParams['PDC_' + key.toUpperCase()] = leadParams[key]
    })
    sendEvent({
        event: 'pdc.signupStart',
        PDC_gtm_funnel_version: 'Enhanced SignUp', // eslint-disable-line @typescript-eslint/naming-convention
        ...pdcLeadParams
    })
}

/**
 * Event to be sent on changing between the top-level steps of the sign-up flow
 */
export const reportStepChange = (newStep: string): void => {
    sendEvent({
        event: 'pdc.stepChange',
        PDC_STEP: newStep, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_ACTION: newStep // eslint-disable-line @typescript-eslint/naming-convention
    })
}

/**
 * Event to be sent when a number picker option has been choosen (eg. Local, Toll-Free)
 * This represents the approach to picking a number choosen by the user
 */
export const pickNumberType = (numberType: string): void => {
    sendEvent({
        event: 'pick-number-type',
        PDC_NUMBER_TYPE: numberType // eslint-disable-line @typescript-eslint/naming-convention
    })
}

/**
 * Event to be sent when the user visits the local number page and we automatically detect their
 * area code, then display numbers for it
 */
export const autoDetectedAreacode = (areaCode: string): void => {
    sendEvent({
        event: 'auto-detect-area-code',
        PDC_AREA_CODE: areaCode, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_LABEL: areaCode // eslint-disable-line @typescript-eslint/naming-convention
    })
}

/**
 * Event to be sent when the user enters a complete area code as part of a local number search
 */
export const selectAreaCode = (areaCode: string): void => {
    sendEvent({
        event: 'select-area-code',
        PDC_AREA_CODE: areaCode, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_LABEL: areaCode // eslint-disable-line @typescript-eslint/naming-convention
    })
}

/**
 * Event sent when the user has begun a custom number search
 */
export const customSearch = (text: string): void => {
    sendEvent({
        event: 'custom-numbers-search',
        PDC_TEXT: text // eslint-disable-line @typescript-eslint/naming-convention
    })
}

/**
 * Event sent when the user has successfully reserved a phone number
 *
 * @param phoneNumber The phone number in international format eg. "+12223334444"
 * @param cost  The one-time fee associated with the number, may be zero (0) for free numbers
 * @param isRecommendedNumber Set to true if the number was listed as the most recommended number
 */
export const reserveNumber = (phoneNumber: string, cost: number, isRecommendedNumber: boolean): void => {
    const areaCode = phoneNumber.slice(2, 5)
    sendEvent({
        event: 'reserve-number',
        PDC_PHONE_NUMBER: phoneNumber, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_AREA_CODE: areaCode, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_COST: cost, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_RECOMMENDED_NUMBER: isRecommendedNumber // eslint-disable-line @typescript-eslint/naming-convention
    })
}

/**
 * Lead attempted to reserve a number, but that number was reserved by some other lead.
 */
export const numberAlreadyTaken = (phoneNumber: string): void => {
    sendEvent({
        event: 'number-already-taken',
        PDC_PHONE_NUMBER: phoneNumber // eslint-disable-line @typescript-eslint/naming-convention
    })
}

/**
 * Unexpected errors when attempting to reserve a number
 */
export const reserveNumberError = (error: Error): void => {
    sendEvent({
        event: 'reserve-number-error',
        PDC_ERROR_MESSAGE: error.message // eslint-disable-line @typescript-eslint/naming-convention
    })
}

const transmittedBusinessInfo = {}

/**
 * Event sent when any business info field has been altered.
 * We only transmit changes to values, redact certain fields for privacy,
 * and map the fields to PDC_* style keys.
 */
export const businessInfoChanged = debounce((businessInfo: Lead): void => {
    const redactedKeys = ['password']
    const changedValues = {}
    Object.keys(businessInfo).filter(key => businessInfo[key]).forEach(key => {
        if (redactedKeys.includes(key)) {
            return
        }
        const gtmKey = `PDC_${key.toUpperCase()}`
        if (!transmittedBusinessInfo[gtmKey] || transmittedBusinessInfo[gtmKey] !== businessInfo[key]) {
            changedValues[gtmKey] = businessInfo[key]
        }
    })

    // Transmit only values changed since we last sent them
    if (Object.keys(changedValues).length) {
        sendEvent({
            event: 'business-info-changed',
            ...changedValues
        })
        Object.assign(transmittedBusinessInfo, changedValues)
    }
}, 1000)

let emailProvidedSent = false

/**
 * Sent the first time a valid email is entered
 */
export const emailProvided = (email: string): void => {
    if (emailProvidedSent) {
        return // We only want this sent once, even if the email was changed
    }
    sendEvent({
        event: 'pdc.emailProvided',
        PDC_ACTION: email, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_LABEL: email // eslint-disable-line @typescript-eslint/naming-convention
    })
    emailProvidedSent = true
}

/**
 * Event sent when a coupon has been successfully applied to the bill
 */
export const couponApplied = (code: string): void => {
    sendEvent({
        event: 'pdc.couponApplied',
        PDC_COUPON_CODE: code, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_COUPON_VALID: true // eslint-disable-line @typescript-eslint/naming-convention
    })
}

/**
 * Event sent when a coupon code entered by the user was found to be invalid.
 */
export const couponRejected = (code: string): void => {
    sendEvent({
        event: 'pdc.couponApplied',
        PDC_COUPON_CODE: code, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_COUPON_VALID: false // eslint-disable-line @typescript-eslint/naming-convention
    })
}

/**
 * Event sent when the user removes a coupon code that is currently applied.
 */
export const couponCleared = (): void => {
    sendEvent({
        event: 'pdc.couponCleared',
        PDC_COUPON_CODE: '' // eslint-disable-line @typescript-eslint/naming-convention
    })
}

/**
 * Event sent when the user starts the process of changing the payment period
 */
export const paymentPeriodChanging = (newPaymentPeriod: number): void => {
    const textValue = newPaymentPeriod === 12 ? 'annual' : 'monthly'
    // For compatibility with older sign-ups, the value must be transmitted in several different ways
    sendEvent({
        event: 'pdc.paymentPeriodChanging',
        PDC_PAYMENT_PERIOD: textValue, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_PERIOD: textValue, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_LABEL: String(newPaymentPeriod) // eslint-disable-line @typescript-eslint/naming-convention
    })
}

/**
 * Event sent when the payment period has been sent, and when the order summary is first loaded
 */
export const paymentPeriodSet = (newPaymentPeriod: number): void => {
    const textValue = newPaymentPeriod === 12 ? 'annual' : 'monthly'
    // For compatibility with older sign-ups, the value must be transmitted in several different ways
    sendEvent({
        event: 'pdc.paymentPeriodSet',
        PDC_PAYMENT_PERIOD: textValue, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_PERIOD: textValue, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_LABEL: String(newPaymentPeriod) // eslint-disable-line @typescript-eslint/naming-convention
    })
}

/**
 * Event sent every time an order summary has been loaded.
 */
export const cartCalculated = (bill: Bill, paymentPeriod: string): void => {
    const formatPrice = (num: number) => {
        num = parseFloat(num) || 0
        return num > 100000 ? (num / 1000000) : num
    }

    const transactionProducts = []
    let transactionTax = 0
    let foundTransactionProduct
    bill.details.forEach((billSection: BillSection) => {
        billSection.charges.forEach((charge: Charge) => {
            switch (billSection.section) {
                    case 'plan':
                        if (charge.code) {
                            transactionProducts.push({
                                sku: charge.code,
                                name: charge.name,
                                category: 'service',
                                price: formatPrice(charge.cost),
                                quantity: '1'
                            })
                        }
                        break
                    case 'phone_numbers':
                        transactionProducts.push({
                            sku: charge.code,
                            name: charge.name.replace(/^((?:\(\d{3}\)|\d{3}) )?\d{3}[- ]?\d{4}(.*)$/g, '$1XXX-XXXX$2'),
                            category: 'numbers',
                            price: formatPrice(charge.cost),
                            quantity: '1'
                        })
                        break
                    case 'extensions':
                        foundTransactionProduct = transactionProducts.find(p => p.name === charge.name)
                        if (foundTransactionProduct) { foundTransactionProduct.quantity++ } else {
                            transactionProducts.push({
                                sku: charge.code,
                                name: charge.name,
                                category: 'extensions',
                                price: formatPrice(charge.cost),
                                quantity: 1
                            })
                        }
                        break
                    case 'fees_and_taxes':
                        transactionTax = transactionTax + formatPrice(charge.cost)
                        break
            }
        })
    })

    const dataLayerPayload = {
        event: 'pdc.cartCalculated',
        transactionProducts: transactionProducts,
        transactionTax: parseFloat(transactionTax.toFixed(2)),
        transactionTotal: parseFloat(bill.total.toFixed(2)),
        PDC_PAYMENT_PERIOD: paymentPeriod === '12' ? 'annual' : 'monthly', // eslint-disable-line @typescript-eslint/naming-convention
        PDC_COUPON_CODE: bill.coupon_code // eslint-disable-line @typescript-eslint/naming-convention
    }

    if (bill.tracking_id) {
        dataLayerPayload.PDC_TRACKING_ID = bill.tracking_id
    }

    sendEvent(dataLayerPayload)
}

/**
 * Event triggered when the ReCaptcha has been executed, beginning
 * the human verification process.
 */
export const captchaStarted = (): void => {
    sendEvent({
        event: 'CAPTCHAStarted'
    })
}

/**
 * When the user has completed the ReCaptcha process (token acquired)
 */
export const captchaSucceeded = (): void => {
    sendEvent({
        event: 'CAPTCHASucceeded'
    })
}

/**
 * On internal errors triggered by react-google-recaptcha
 */
export const captchaError = (error: Error): void => {
    sendEvent({
        event: 'CAPTCHAError',
        PDC_ACTION: error.message, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_LABEL: error.name // eslint-disable-line @typescript-eslint/naming-convention
    })
}

/**
 * When the Google ReCaptcha session has expired
 */
export const captchaExpired = (): void => {
    sendEvent({
        event: 'CAPTCHAExpired'
    })
}

/**
 * Sent when the backend has deemed this lead is exempt from having to complete Multi-Factor Authentication (MFA)
 *
 * @param details list of key/values indicating possible reasons why their exempt and a value of true if that reason/condition was met
 */
export const mfaExempt = (details: { is_sales: boolean, is_ip_trusted: boolean }): void => { // eslint-disable-line @typescript-eslint/naming-convention
    sendEvent({
        ...details,
        event: 'MFAExempt'
    })
}

/**
 * Event sent for unexpected errors with the transactions page (eg. the order summary fails to load)
 */
export const transactionPageError = (errorMessage: string, reason = 'unknown cause'): void => {
    sendEvent({
        event: 'pdc.transactionPageError',
        PDC_ACTION: errorMessage, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_LABEL: reason // eslint-disable-line @typescript-eslint/naming-convention
    })
}

/**
 *  Event sent for ValidationErrors, these are expected errors related to the data entered by the user.
 */
export const validationError = (errorMessage: string, errorCode?: number): void => {
    sendEvent({
        event: 'pdc.validationError',
        PDC_ACTION: errorMessage, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_LABEL: errorCode ? `ERROR_CODE.${errorCode}` : '' // eslint-disable-line @typescript-eslint/naming-convention
    })
}

/**
 *  Event sent for arbitrary errors during the checkout process, such as a technical error saving data, etc.
 */
export const checkoutError = (errorMessage: string, reason = 'unknown cause'): void => {
    sendEvent({
        event: 'pdc.transactionError',
        PDC_ACTION: errorMessage, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_LABEL: reason // eslint-disable-line @typescript-eslint/naming-convention
    })
}

/**
 *  Event sent when the "Create your account" process fails for any reasons.
 *  These include things like payment information processing (Stripe) related issues,
 *  account creation errors, and more.
 */
export const transactionError = (error: Error, reason = 'unknown cause'): void => {
    const data = {
        event: 'pdc.transactionError',
        PDC_ACTION: error.message, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_LABEL: reason // eslint-disable-line @typescript-eslint/naming-convention
    }

    if (error instanceof CreateAccountError) {
        data.PDC_CREATE_ACCOUNT_ERRORS = error.createAccountErrors
    }

    sendEvent(data)
}

/**
 * Event sent when the "transaction" - the account creation process, has succeeded
 */
export const transactionComplete = (accountDetails: AccountDetails): void => {
    sendEvent({
        event: 'transactionComplete',
        transactionId: accountDetails.account_id,
        transactionTotal: accountDetails.total_charged,
        PDC_PACKAGE: accountDetails.package, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_BASIC_USER_TIERED: accountDetails.basic_users, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_PLUS_USER_UNLIMITED_TIERED: accountDetails.plus_users, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_PRO_USER_UNLIMITED_TIERED: accountDetails.pro_users, // eslint-disable-line @typescript-eslint/naming-convention
        PDC_PAYMENT_PERIOD: accountDetails.payment_period // eslint-disable-line @typescript-eslint/naming-convention
    })
}
