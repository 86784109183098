import React, { useCallback, useEffect, useState } from 'react'
import * as API from '../../../util/API'
import * as GTM from '../../../util/GTM'
import { PhoneNumberData } from '../../../types'
import { mergePhoneNumbers } from '../../../util/PhoneNumberHelpers'
import LocalNumberSearchBar from './LocalNumberSearchBar'
import NumberSearchResults from '../NumberSearchResults'
import Alert, { Color as AlertColor } from '../../../design-components/Alert'
import Typography from 'typography'

interface ChooseAreaCodeProps {
    areaCodeOptions: string[]
}

/**
 * UI displayed when the user prefers to choose a specific area code (rather than find numbers by IP address)
 */
const ChooseAreaCode = ({ areaCodeOptions } : ChooseAreaCodeProps) : JSX.Element => {
    const [areaCode, setAreaCode] = useState<string>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [numbers, setNumbers] = useState<PhoneNumberData[]>([])
    const [error, setError] = useState<null | Error>(null)

    const search = useCallback((loadMore = false) => {
        if (areaCode.length < 3) {
            return // user has not yet entered a complete area code
        }

        setLoading(true)
        setError(null)
        if (!loadMore) {
            setNumbers([])
        }

        API.getNumbersByAreaCode(areaCode).then(response => {
            const updateNumbers = mergePhoneNumbers(loadMore ? numbers : [], response?.data.numbers)
            setNumbers(updateNumbers)

            if (!loadMore) {
                GTM.selectAreaCode(areaCode)
            }
        }, error => setError(error)).then(() => setLoading(false))
    }, [areaCode, numbers])

    useEffect(() => {
        search()
    }, [areaCode])

    const loadMoreNumbers = () => {
        search(true)
    }

    return (
        <>
            {error &&
                <Alert
                    showIcon={true}
                    color={AlertColor.ERROR}
                    content={<>An error occured while trying to load search results</>}
                />
            }

            <LocalNumberSearchBar
                areaCodeOptions={areaCodeOptions}
                areaCode={areaCode}
                setAreaCode={setAreaCode}
            />

            {/* Display the results (with no recommended number) below the search form */}
            <NumberSearchResults
                noRecommendedNumber={true}
                numbers={numbers}
                zeroNumbersMessage={<Typography variant="body2" remoteConfigID="signup_local_numbers_no_numbers" />}
                loading={loading}
                loadMoreNumbers={loadMoreNumbers}
            />
        </>
    )
}

export default ChooseAreaCode
