import React, { useState, useContext } from 'react'
import OrderLayout, { OrderLayoutLeftColumn, OrderLayoutRightColumn } from '../OrderLayout'
import OrderSummary from '../checkout/order-summary/OrderSummary'
import BusinessInfo from './BusinessInfo'
import SubmitButton from '../../design-components/SubmitButton'
import LeadContext from '../../LeadContext'
import Alert, { Color as AlertColor } from '../../design-components/Alert'
import Spinner from 'spinner'
import { createStyles, makeStyles, useTheme } from '@material-ui/core'

const useStyles = makeStyles(theme =>
    createStyles({
        submitContainer: {
            marginTop: '30px',
            marginBottom: '20px'
        }
    })
)

/**
 * Business Details Step
 */
const BusinessDetailsStep = () => {
    const classes = useStyles()
    const theme = useTheme()

    const [error, setError] = useState<null | Error>(null)

    const { saveLeadParams, savingLead } = useContext(LeadContext)

    const onSubmit = async event => {
        event.preventDefault()

        setError(null)

        try {
            await saveLeadParams(
                ['company_name', 'first_name', 'last_name', 'email', 'personal_phone_number', 'password'],
                true // perform MFA setup afterwards (unless lead is exempt)
            )
        } catch (error) {
            setError(error)
        }
    }

    return (
        <form onSubmit={onSubmit}>
            {error &&
                <Alert
                    data-testid="error-alert"
                    showIcon={true}
                    color={AlertColor.ERROR}
                    content={error.message}
                />
            }

            <OrderLayout>
                <OrderLayoutLeftColumn>
                    <BusinessInfo />
                </OrderLayoutLeftColumn>

                <OrderLayoutRightColumn>
                    <OrderSummary
                        disabled={savingLead}
                    />

                    <div className={classes.submitContainer}>
                        <SubmitButton disabled={savingLead}>
                            {savingLead
                                ? (
                                    <Spinner size="sm" color={theme.palette.common.black} />
                                )
                                : (
                                    <>Continue</>
                                )}
                        </SubmitButton>
                    </div>
                </OrderLayoutRightColumn>
            </OrderLayout>
        </form>
    )
}

export default BusinessDetailsStep
