import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from './styles'
import { ThemeProvider } from 'theme-provider'

const useStyles = makeStyles(styles)

interface SelectOption {
    value: string;
    label: string;
}

interface SelectProps {
    label?: string;
    name?: string;
    value?: string
    options: SelectOption[];
    onChange?: React.ChangeEventHandler<HTMLSelectElement>;
    disabled?: boolean;
    'data-testid'?: string;
}

/**
 * Select component. This is a non-material UI based select component that uses proper
 * semantic HTML elements (select and option elements).
 * This component responds better to browser auto-fill functionality, compared to select-mui.
 *
 * @param {SelectProps} props - props
 */
export const Select = ({ options, name, value, label, onChange, ...otherProps }: SelectProps): JSX.Element => {
    const classes = useStyles()

    return (
        <ThemeProvider>
            <div data-testid={otherProps['data-testid']} className={classes.formControl}>
                <select className={classes.select} name={name} value={value} onChange={onChange}>
                    <option value="" hidden />
                    {options.map(option => (
                        <option className={classes.option} key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
                <label className={`${classes.label} ${value === '' ? classes.placeholderLabel : ''}`}>
                    {label}
                </label>
            </div>
        </ThemeProvider>
    )
}

Select.defaultProps = {
    label: '',
    disabled: false,
    name: ''
}

export default Select
