import { PhoneNumberData } from '../types'

/**
 * Merges lists of PhoneNumbers, by the phone_number field
 * TODO: in the future we may want to (if possible) change the APIs to use "ids" instead
 */
export const mergePhoneNumbers = (list1: PhoneNumberData[], list2: PhoneNumberData[]) : PhoneNumberData[] => {
    const list = [...list1]
    list2.forEach(newNum => {
        if (list.every(n => n.phone_number !== newNum.phone_number)) {
            list.push(newNum)
        }
    })
    return list
}
