import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon'
import { SvgIconProps } from '@material-ui/core'

/**
 * @param {SvgIconProps} props - svg props
 */
export const ABCLogo = (props: SvgIconProps): JSX.Element => {
    const svgProps = Object.assign({}, props)
    delete svgProps.color
    return (
        <SvgIcon width="68" height="22" viewBox="0 0 68 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...svgProps}>
            <g opacity="0.35">
                <rect y="1" width="20" height="20" rx="2" fill="white"/>
                <rect x="24" y="1" width="20" height="20" rx="2" fill="white"/>
                <rect x="48" y="1" width="20" height="20" rx="2" fill="white"/>
            </g>
            <path d="M13.3324 14.008H7.09238L5.74838 17H4.51638L9.63638 5.8H10.8044L15.9244 17H14.6764L13.3324 14.008ZM12.9004 13.048L10.2124 7.032L7.52438 13.048H12.9004ZM36.8331 11.224C37.5691 11.384 38.1345 11.704 38.5291 12.184C38.9345 12.6533 39.1371 13.2613 39.1371 14.008C39.1371 14.968 38.7798 15.7093 38.0651 16.232C37.3611 16.744 36.3158 17 34.9291 17H29.7771V5.8H34.6091C35.8571 5.8 36.8225 6.05067 37.5051 6.552C38.1985 7.05333 38.5451 7.76267 38.5451 8.68C38.5451 9.29867 38.3905 9.82667 38.0811 10.264C37.7825 10.6907 37.3665 11.0107 36.8331 11.224ZM30.9611 6.776V10.84H34.5291C35.4358 10.84 36.1345 10.6693 36.6251 10.328C37.1158 9.976 37.3611 9.46933 37.3611 8.808C37.3611 8.14667 37.1158 7.64533 36.6251 7.304C36.1345 6.952 35.4358 6.776 34.5291 6.776H30.9611ZM34.9131 16.024C35.9158 16.024 36.6731 15.8533 37.1851 15.512C37.6971 15.1707 37.9531 14.6373 37.9531 13.912C37.9531 12.5147 36.9398 11.816 34.9131 11.816H30.9611V16.024H34.9131ZM58.7036 17.096C57.5943 17.096 56.5916 16.8507 55.6956 16.36C54.8103 15.8693 54.1116 15.192 53.5996 14.328C53.0983 13.4533 52.8476 12.4773 52.8476 11.4C52.8476 10.3227 53.0983 9.352 53.5996 8.488C54.1116 7.61333 54.8156 6.93067 55.7116 6.44C56.6076 5.94933 57.6103 5.704 58.7196 5.704C59.5516 5.704 60.3196 5.84267 61.0236 6.12C61.7276 6.39733 62.325 6.80267 62.8156 7.336L62.0636 8.088C61.189 7.20267 60.085 6.76 58.7516 6.76C57.8663 6.76 57.061 6.96267 56.3356 7.368C55.6103 7.77333 55.0396 8.328 54.6236 9.032C54.2183 9.736 54.0156 10.5253 54.0156 11.4C54.0156 12.2747 54.2183 13.064 54.6236 13.768C55.0396 14.472 55.6103 15.0267 56.3356 15.432C57.061 15.8373 57.8663 16.04 58.7516 16.04C60.0956 16.04 61.1996 15.592 62.0636 14.696L62.8156 15.448C62.325 15.9813 61.7223 16.392 61.0076 16.68C60.3036 16.9573 59.5356 17.096 58.7036 17.096Z" fill="white"/>
        </SvgIcon>
    )
}

export default ABCLogo
