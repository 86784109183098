import React, { useContext, useEffect, useRef, useState } from 'react'
import OrderSummary from './order-summary/OrderSummary'
import SecureCheckoutForm from './SecureCheckoutForm'
import * as API from '../../../src/util/API'
import * as GTM from '../../../src/util/GTM'
import CreatingYourAccount from './CreatingYourAccount'
import Alert, { Color as AlertColor } from '../../design-components/Alert'
import { AccountDetails } from '../../types'
import { injectStripe, Stripe } from 'react-stripe-elements'
import ReCAPTCHA from 'react-google-recaptcha'
import { ValidationError } from '../../util/Validations'
import { createStyles, makeStyles } from '@material-ui/core'
import Typography from 'typography'
import AccountCreated from './AccountCreated'
import Spinner from 'spinner'
import orderSummaryContext from './order-summary/OrderSummaryContext'
import OrderLayout, { OrderLayoutLeftColumn, OrderLayoutRightColumn } from '../OrderLayout'
import LeadContext, { LeadContextValue } from '../../LeadContext'
import SubmitButton from '../../design-components/SubmitButton'
import CheckoutBadges from './CheckoutBadges'

const useStyles = makeStyles(theme =>
    createStyles({
        form: {
            margin: '0 auto',
            maxWidth: '810px'
        },
        termsAndConditions: {
            marginTop: '20px',
            fontSize: '11px !important',
            lineHeight: '16px !important',
            '& a': {
                color: '#6E7A82',
                fontWeight: '500',
                textDecoration: 'underline',
                fontSize: '10.5px',
                lineHeight: '16px',
                letterSpacing: '0.1px'
            }
        },
        submitContainer: {
            marginTop: '30px',
            marginBottom: '20px'
        }
    })
)

interface CheckoutProps {
    stripe: Stripe;
    setShowStepper: (showStepper: boolean) => void;
    setDisableStepper: (disableStepper: boolean) => void;
}

/**
 * Checkout step of sign-up
 */
const Checkout = ({ stripe, setShowStepper, setDisableStepper } : CheckoutProps) : JSX.Element => {
    const classes = useStyles()

    const { saveLeadParams } = useContext<LeadContextValue>(LeadContext)

    const orderSummary = useContext(orderSummaryContext)
    const { bill } = orderSummary

    const [error, setError] = useState<Error | null>(null)
    const [processing, setProcessing] = useState(false)
    const [isCreatingAccount, setIsCreatingAccount] = useState<boolean>(false)
    const [accountDetails, setAccountDetails] = useState<AccountDetails | null>(null)

    const recaptchaRef = useRef()

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search)
        if (searchParams.get('returned_from_mfa')) {
            console.info('MFA: User has returned from the MFA app, starting the account creation process...')
            createAccount()
        }
    }, [])

    const onSubmit = async event => {
        event.preventDefault()

        if (processing) {
            return // Prevent submitting again by pressing enter, etc.
        }

        setError(null) // clear any previous error
        setProcessing(true)

        const tasks = [
            { fn: () => saveFinalLeadParams(), description: 'saving lead data' },
            { fn: () => savePaymentToken(), description: 'payment information processing' },
            { fn: () => verifyHumanUser(), description: 'human verification' }
        ]
        try {
            for (const task of tasks) {
                await task.fn().catch(error => {
                    if (error instanceof ValidationError) {
                        GTM.validationError(error.message, error.errorCode)
                    } else {
                        GTM.checkoutError(error.message, task.description)
                    }
                    throw error
                })
            }
        } catch (error) {
            onError(error)
        }
    }

    const onError = (error: Error) => {
        console.info('Submission error: ', error)
        setError(error)
        setProcessing(false)
        setIsCreatingAccount(false)
        window.scrollTo(0, 0)
    }

    const saveFinalLeadParams = async () => {
        await saveLeadParams(['street', 'country', 'billing_country_code', 'state', 'city', 'billing_zip_code'])
    }

    const savePaymentToken = async () => {
        if (bill?.skip_credit_card_entry) {
            return
        }

        const result = await stripe.createToken({ type: 'card' })

        if (result.error) {
            throw result.error
        } else {
            const stripePaymentToken = result.token.id
            await API.createPaymentToken(stripePaymentToken)
        }
    }

    // Starts the process of google recaptcha verifying the user is a human
    const verifyHumanUser = async () => {
        if (window.location.hostname === 'localhost') {
            // explicitly tell the dev, elsewise calling execute() below will silently fail never calling onRecaptchaChange
            throw new Error('google recaptcha can not execute when running this app locally (on localhost)')
        }

        // Intentionally stop the "processing" state undisabling the UI including the submit button,
        // so that if the user dismisses the challenge modal (eg. clicking outside of it) they can restart the process by submitting again.
        setProcessing(false)

        recaptchaRef.current.execute()

        GTM.captchaStarted()
    }

    // JS callback done when recaptcha has finished verify the user is a human
    const onRecaptchaChange = async (recaptchaResponseToken: string) => {
        console.log(`onRecaptchaChange: token: ${recaptchaResponseToken}`)

        setProcessing(true)

        GTM.captchaSucceeded()

        const onRecaptchaError = () => {
            throw new Error('There was a problem verifying you are a human, please refresh the page and try again')
        }

        const response = await API.verifyRecaptcha(recaptchaResponseToken).catch(onRecaptchaError)

        if (response?.data?.success) {
            await createAccount()
        } else {
            onRecaptchaError()
        }
    }

    const onRecaptchaExpired = () => {
        console.info('ReCAPTCHA Expired')
        GTM.captchaExpired()
        onError(new Error('Your ReCAPTCHA session used to verify that your a human has expired, please try again'))
    }

    const onRecaptchaError = (error: Error) => {
        console.error(`ReCAPTCHA Error: ${error.name} ${error.message}`)
        GTM.captchaError(error)
        onError(new Error('An error occured while trying to verify that your a human, please try again'))
    }

    const createAccount = async () => {
        setIsCreatingAccount(true)
        try {
            const accountDetails = await API.createAccount()
            setAccountDetails(accountDetails)
        } catch (error) {
            onError(error)
            GTM.transactionError(error, 'account creation')
        }
    }

    useEffect(() => {
        setDisableStepper(processing || isCreatingAccount)
    }, [processing, isCreatingAccount])

    useEffect(() => {
        // For UX reasons we're hiding the Stepper when the account is created,
        // as there is no going back to previous steps at this point
        setShowStepper(!accountDetails)
    }, [accountDetails])

    if (accountDetails) return <AccountCreated accountDetails={accountDetails}/>
    if (isCreatingAccount) return <CreatingYourAccount />

    return (
        <>
            <form id="checkout-form" name="checkout-form" className={classes.form} onSubmit={onSubmit}>
                {error &&
                    <Alert
                        data-testid="error-alert"
                        showIcon={true}
                        color={AlertColor.ERROR}
                        content={error.message}
                    />
                }

                <OrderLayout>
                    <OrderLayoutLeftColumn>
                        <SecureCheckoutForm skipCreditCardEntry={bill?.skip_credit_card_entry || false} />
                    </OrderLayoutLeftColumn>

                    <OrderLayoutRightColumn>
                        <OrderSummary
                            disabled={processing}
                        />

                        <Typography variant="helperText" color="#6E7A82" display="block" align="left" className={classes.termsAndConditions}>
                            By clicking &quot;Create your account&quot;, you agree to the <a href={process.env.REACT_APP_TERMS_OF_SERVICE_URL} target="_blank" rel="noreferrer">Terms of Service</a> and <a href={process.env.REACT_APP_PRIVACY_POLICY_URL} target="_blank" rel="noreferrer">Privacy Policy</a>. You will receive text and email messages with information and special offers. You may opt-out by texting &quot;STOP&quot;. Message frequency will vary. Message and data rates may apply.
                        </Typography>

                        <div className={classes.submitContainer}>
                            <SubmitButton
                                disabled={processing || !bill || (bill?.coupon_code === 'error')}
                            >
                                {processing
                                    ? (
                                        <Spinner color="black" />
                                    )
                                    : (
                                        <>Create your account</>
                                    )}
                            </SubmitButton>
                        </div>

                        <CheckoutBadges />
                    </OrderLayoutRightColumn>
                </OrderLayout>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    badge="bottomleft"
                    sitekey="6LcEHXAUAAAAALeg1BlVWZNcvo6LCOf_mwa8rh1P"
                    onChange={onRecaptchaChange}
                    onError={onRecaptchaError}
                    onExpired={onRecaptchaExpired}
                />
            </form>
        </>
    )
}

export default injectStripe(Checkout)
