import React, { useState, useEffect } from 'react'
import * as API from './util/API'
import Spinner from 'spinner'
import Alert, { Color as AlertColor } from './design-components/Alert'
import { useHistory } from 'react-router-dom'
import { LeadParams } from './types'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import LeadContext from './LeadContext'
import * as GTM from './util/GTM'
import { removeCookie, setCookie } from './util/Cookies'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        spinnerWrapper: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }
    })
)

interface LeadProviderProps {
    children: JSX.Element
}

/**
 * Inits the pdc_lead_session_token, which is required by many app.phone.com APIs
 */
const LeadProvider = ({ children } : LeadProviderProps) : JSX.Element => {
    const classes = useStyles()

    const history = useHistory()

    const [leadParams, setLeadParams] = useState<null | LeadParams>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<Error | null>(null)

    const initLead = () => {
        setLoading(true)
        API.initLead().then((leadParams: LeadParams | null) => {
            setError(null)

            // LeadParams may be null in the event that initLead() is redirecting you to the pricing page, country not supported, etc.
            if (leadParams) {
                if (leadParams.package !== 'voip_user_tiered') {
                    // User originated from classic signup
                    setCookie('is_classic_signup', 'true')
                } else {
                    removeCookie('is_classic_signup')
                }

                if (leadParams.converted) {
                    console.info('Lead already converted, clearing pdc_lead_session_token cookie and navigating to pricing page...')
                    removeCookie('pdc_lead_session_token')
                    alert('Your sign-up session has been completed, you will now be redirected to the pricing page where you may sign-up again if you wish')
                    API.redirectToPricingPage()
                    return
                }

                GTM.signUpStart(leadParams)

                setLeadParams(leadParams)

                // Automatically move existing leads that have already choosen a number to the checkout page.
                // Everyone else will start of at the URL the entered the app through
                if (leadParams.cart?.length) {
                    const searchParams = new URLSearchParams(window.location.search)
                    searchParams.set('session_token', leadParams.session_token)
                    history.push(`/checkout?${searchParams.toString()}`)
                }

                setLoading(false)
            }
        }, error => {
            setError(error)
            setLoading(false)
        })
    }

    useEffect(() => {
        initLead()
    }, [])

    return loading
        ? (
            <span className={classes.spinnerWrapper}>
                <Spinner size="big" data-testid="loading-spinner" />
            </span>
        )
        : error
            ? (
                <Alert
                    data-testid="error-alert"
                    showIcon={true}
                    color={AlertColor.ERROR}
                    content={<>An error occured while trying to load this page, <a onClick={initLead}>Click here to try again</a></>}
                />
            )
            : (
                <LeadContext.Provider value={leadParams}>
                    {children}
                </LeadContext.Provider>
            )
}

export default LeadProvider
