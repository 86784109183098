import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme =>
    createStyles({
        listContainer: {
            display: 'flex',
            justifyContent: 'center'
        },
        checkoutBadgesList: {
            padding: 0,
            marginBottom: 0,
            textAlign: 'left',
            listStyleType: 'none',
            '& li': {
                color: theme.palette.text.primary,
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '15px',
                margin: '6px 0'
            },
            '& li svg': {
                width: '18px',
                height: '22px',
                marginRight: '6px'
            }
        }
    })
)

const ShieldCheckIcon = () => (
    <svg width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.27898 6C0.105872 16.4738 2.54386 20.0715 10.279 24C18.5683 20.4847 20.7938 16.7313 19.279 6L10.279 2L1.27898 6Z" fill="#186A82"/>
        <path d="M1.07591 5.54309L0.813991 5.6595L0.782088 5.94435C0.192295 11.2101 0.494792 14.8528 1.99653 17.6885C3.50095 20.5292 6.16117 22.4694 10.0526 24.4458L10.26 24.5512L10.4742 24.4603C14.6489 22.6899 17.4143 20.8141 18.8999 17.9353C20.3782 15.0705 20.5343 11.3157 19.7741 5.93011L19.7353 5.65566L19.482 5.54309L10.482 1.54309L10.279 1.45284L10.0759 1.54309L1.07591 5.54309Z" stroke="#85929A" strokeOpacity="0.65"/>
        <path d="M6.05547 12.4164C5.95744 12.3212 5.80114 12.3223 5.70452 12.4189L4.44469 13.6788C4.34688 13.7766 4.34709 13.9352 4.44516 14.0328L8.50937 18.0758C8.60868 18.1746 8.76978 18.1726 8.86651 18.0712L16.3081 10.2764C16.4019 10.1781 16.4001 10.023 16.304 9.92695L15.0454 8.66826C14.9465 8.56942 14.7859 8.57082 14.6888 8.67137L8.64426 14.9303L6.05547 12.4164Z" fill="white"/>
    </svg>
)

const ShieldComputerIcon = () => (
    <svg width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.27898 6C0.105872 16.4738 2.54386 20.0715 10.279 24C18.5683 20.4847 20.7938 16.7313 19.279 6L10.279 2L1.27898 6Z" fill="#186A82"/>
        <path d="M1.07591 5.54309L0.813991 5.6595L0.782088 5.94435C0.192295 11.2101 0.494792 14.8528 1.99653 17.6885C3.50095 20.5292 6.16117 22.4694 10.0526 24.4458L10.26 24.5512L10.4742 24.4603C14.6489 22.6899 17.4143 20.8141 18.8999 17.9353C20.3782 15.0705 20.5343 11.3157 19.7741 5.93011L19.7353 5.65566L19.482 5.54309L10.482 1.54309L10.279 1.45284L10.0759 1.54309L1.07591 5.54309Z" stroke="#85929A" strokeOpacity="0.65"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M6.08334 7.89583C5.53106 7.89583 5.08334 8.34354 5.08334 8.89583V14.4792C5.08334 15.0314 5.53106 15.4792 6.08334 15.4792H14.9167C15.469 15.4792 15.9167 15.0314 15.9167 14.4792V8.89583C15.9167 8.34354 15.469 7.89583 14.9167 7.89583H6.08334ZM6.39584 8.97916C6.1197 8.97916 5.89584 9.20302 5.89584 9.47916V13.8958C5.89584 14.172 6.1197 14.3958 6.39584 14.3958H14.6042C14.8803 14.3958 15.1042 14.172 15.1042 13.8958V9.47916C15.1042 9.20302 14.8803 8.97916 14.6042 8.97916H6.39584Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.75 15.4792C13.75 15.4792 11.3125 15.4792 11.3125 16.0208C11.3125 16.32 11.5833 16.5625 11.5833 16.5625H13.0729C13.1477 16.5625 13.2083 16.6231 13.2083 16.6979V17.1042C13.2083 17.1789 13.1477 17.2396 13.0729 17.2396H7.92704C7.85225 17.2396 7.79162 17.1789 7.79162 17.1042V16.6979C7.79162 16.6231 7.85225 16.5625 7.92704 16.5625H9.41662C9.41662 16.5625 9.68745 16.32 9.68745 16.0208C9.68745 15.4792 12.125 15.4792 12.125 15.4792C-4.11348 15.4792 22.4769 15.4792 13.75 15.4792Z" fill="white"/>
    </svg>
)

const ShieldReceptionistIcon = () => (
    <svg width="21" height="26" viewBox="0 0 21 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.27898 6C0.105872 16.4738 2.54386 20.0715 10.279 24C18.5683 20.4847 20.7938 16.7313 19.279 6L10.279 2L1.27898 6Z" fill="#186A82"/>
        <path d="M1.07591 5.54309L0.813991 5.6595L0.782088 5.94435C0.192295 11.2101 0.494792 14.8528 1.99653 17.6885C3.50095 20.5292 6.16117 22.4694 10.0526 24.4458L10.26 24.5512L10.4742 24.4603C14.6489 22.6899 17.4143 20.8141 18.8999 17.9353C20.3782 15.0705 20.5343 11.3157 19.7741 5.93011L19.7353 5.65566L19.482 5.54309L10.482 1.54309L10.279 1.45284L10.0759 1.54309L1.07591 5.54309Z" stroke="#85929A" strokeOpacity="0.65"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.273 10.9269C13.3488 10.9571 13.4325 10.9738 13.5206 10.9738C13.8613 10.9738 14.1379 10.7231 14.1379 10.414V9.54637C14.1379 9.27543 13.9268 9.05029 13.6465 8.99778L13.623 8.90149C13.4886 7.43372 12.133 6.06895 10.2893 6.06895C8.4457 6.06895 7.10505 7.44604 6.98652 8.92277L6.99145 8.99442C6.70253 9.04144 6.48276 9.27096 6.48276 9.54637V10.414C6.48276 10.7231 6.75945 10.9738 7.10011 10.9738C7.44089 10.9738 7.71746 10.7231 7.71746 10.414V9.54637L7.71005 9.10414C7.71005 7.91626 8.39444 6.53448 10.2893 6.53448C12.1843 6.53448 12.9069 7.91626 12.9069 9.10414C12.9069 9.22057 12.9032 9.54637 12.9032 9.54637V10.414C12.9032 10.5574 12.9628 10.6883 13.0607 10.7873L12.5041 11.3937C12.3615 11.3328 12.1901 11.3647 12.0793 11.4854L11.8705 11.7129C11.73 11.8659 11.7402 12.1039 11.8932 12.2444C12.0463 12.3849 12.2842 12.3747 12.4247 12.2217L12.6336 11.9942C12.7443 11.8735 12.7615 11.7001 12.6888 11.5633L13.273 10.9269ZM8.36994 9.18255C8.36994 9.05827 8.25353 8.95863 8.11124 8.95863C7.96896 8.95863 7.85254 9.05827 7.85254 9.18255V10.75C7.85254 10.8731 7.96896 10.9739 8.11124 10.9739C8.25353 10.9739 8.36994 10.8731 8.36994 10.75V9.18255ZM12.5091 8.95863C12.6514 8.95863 12.7678 9.05827 12.7678 9.18255V10.75C12.7678 10.8731 12.6514 10.9739 12.5091 10.9739C12.3668 10.9739 12.2504 10.8731 12.2504 10.75V9.18255C12.2504 9.05827 12.3668 8.95863 12.5091 8.95863Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.5981 12.6368C11.7002 12.5795 12.5011 11.1941 12.4353 9.76226C12.3695 8.33039 11.4355 7.27176 10.2181 7.33509C9.00077 7.39841 8.19514 8.42698 8.26096 9.85885C8.32678 11.2907 9.49603 12.6942 10.5981 12.6368ZM8.82606 12.6804C8.88769 12.6383 8.93913 12.6032 8.98099 12.5834C9.20831 13.4859 10.0796 14.525 10.9748 14.4645C11.6359 14.4198 11.8402 13.898 11.7795 12.7813C11.8521 12.8176 11.8668 12.8553 11.8826 12.8958C11.8982 12.9356 11.9148 12.9781 11.9884 13.0248C12.2453 13.1616 12.4693 13.2462 12.6669 13.3208C13.3624 13.5833 13.7296 13.7219 14.0436 15.5829C14.1866 16.4301 13.9303 17.3581 13.1656 17.7561C11.2986 18.7279 8.84096 17.9704 7.09063 17.3884C6.18635 17.0878 5.47763 16.2194 5.61704 15.273C5.73483 14.4734 5.96568 13.9976 6.32422 13.573C6.75138 13.0671 7.2869 13.048 7.75098 13.0314C7.83165 13.0285 7.91017 13.0257 7.98558 13.0204C8.36708 12.9937 8.64199 12.8061 8.82606 12.6804Z" fill="white"/>
    </svg>
)

const items = [
    { icon: <ShieldCheckIcon />, text: '30-day money back guarantee' },
    { icon: <ShieldComputerIcon />, text: 'Live Human 24/7 Support' },
    { icon: <ShieldReceptionistIcon />, text: 'Video Meetings Included' }
]

/**
 * List of badges/text indicating key product features that inspire trust in completing the checkout process.
 */
const CheckoutBadges = () => {
    const classes = useStyles()

    return (
        <div className={classes.listContainer}>
            <ul className={classes.checkoutBadgesList}>
                {items.map((item, index) => (
                    <li key={index}>
                        {item.icon}
                        {item.text}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default CheckoutBadges
