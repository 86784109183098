import React from 'react'
import { Divider } from '@material-ui/core'
import BillingCycle from './BillingCycle'
import PhoneNumberCharges from './PhoneNumberCharges'
import UserCharges from './UserCharges'
import TaxesAndFees from './TaxesAndFees'
import Total from './Total'
import Coupon from './Coupon'
import { Bill } from '../../../types'
import { createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() =>
    createStyles({
        divider: {
            width: '100%'
        }
    })
)

interface OrderSummaryProps {
    bill: Bill,
    billingCountryCode: string,
    setPaymentPeriod: (paymentPeriod: number) => void,
    setCouponCode: (coupon: string) => void,
    disabled?: boolean
}

/**
 * Dislays the state of your cart and the totals/prices associated with your order
 */
const BillSections = ({ bill, billingCountryCode, setPaymentPeriod, setCouponCode, disabled } : OrderSummaryProps) : JSX.Element => {
    const classes = useStyles()

    return (
        <>
            <BillingCycle
                paymentPeriod={parseInt(bill.payment_period)}
                onChange={(event, checked) => setPaymentPeriod(checked ? 12 : 1)}
                disabled={disabled} />

            <Divider className={classes.divider} />

            <PhoneNumberCharges bill={bill} />
            <UserCharges bill={bill} />

            <Divider className={classes.divider} />

            <TaxesAndFees bill={bill} />

            {bill.details.some(s => s.section === 'fees_and_taxes') &&
                <>
                    <Divider className={classes.divider} />

                    <Total
                        total={bill.total}
                        totalRecurring={bill.total_recurring}
                        billingCountryCode={billingCountryCode}
                    />
                </>
            }

            <Coupon
                setCouponCode={setCouponCode}
                couponCodeApplied={bill.coupon_code}
                couponCodeAttempted={bill.coupon_code_attempted}
                couponDesc={bill.coupon_desc}
                disabled={disabled} />
        </>
    )
}

export default BillSections
