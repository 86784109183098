import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { createStyles, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme =>
    createStyles({
        link: {
            fontSize: '12px',
            lineHeight: '19px',
            color: '#29921B',
            fontWeight: 'normal',
            '&:hover': {
                color: theme.palette.primary.textDark
            }
        }
    })
)

interface LinkProps {
    to?: string, // optional, since onClick may be used instead
    className?: string
}

/**
 * Customized links to be used throughout sign up
 */
const Link = (props: LinkProps) => {
    const classes = useStyles()

    return (
        <ReactRouterLink {...props} className={`${classes.link} ${props.className}`} />
    )
}

export default Link
