
import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core'
import Typography from 'typography'

const requirements = [
    { condition: /.{8,}$/, label: '8 characters minimum' },
    { condition: /[A-Z]/, label: 'one uppercase letter' },
    { condition: /[a-z]/, label: 'one lowercase letter' }
]

const useStyles = makeStyles(() =>
    createStyles({
        container: {
            margin: '10px 0px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            textAlign: 'left'
        },
        row: {
        }
    })
)

const XSVG = () => {
    return (
        <svg data-testid="x-icon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9ZM6.54974 5.72396C6.4179 5.59201 6.20403 5.59201 6.07219 5.72396L5.72376 6.07269C5.59218 6.20438 5.59207 6.41774 5.7235 6.54957L8.16644 8.99995L5.7235 11.4503C5.59207 11.5822 5.59218 11.7955 5.72376 11.9272L6.07219 12.2759C6.20403 12.4079 6.4179 12.4079 6.54974 12.2759L9.00059 9.82297L11.4516 12.276C11.5834 12.408 11.7973 12.408 11.9291 12.276L12.2775 11.9273C12.4091 11.7956 12.4092 11.5823 12.2778 11.4504L9.83485 9.00005L12.2778 6.54968C12.4092 6.41784 12.4091 6.20449 12.2775 6.0728L11.9291 5.72407C11.7973 5.59212 11.5834 5.59212 11.4516 5.72407L9.0007 8.17703L6.54974 5.72396Z" fill="#B6BEC2"/>
        </svg>
    )
}

const CheckSVG = () => {
    return (
        <svg data-testid="check-icon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1941_7757)">
                <rect x="1.5" y="1.5" width="15" height="15" rx="7.5" fill="#3FAE29"/>
                <path d="M6.49352 8.49835C6.39549 8.40316 6.2392 8.4043 6.14257 8.50093L5.53173 9.11177C5.43392 9.20958 5.43413 9.36823 5.53219 9.46578L7.81499 11.7367C7.9143 11.8355 8.0754 11.8334 8.17213 11.7321L12.4775 7.22233C12.5713 7.1241 12.5695 6.96896 12.4734 6.87292L11.8638 6.26323C11.7649 6.16439 11.6042 6.16579 11.5071 6.26634L7.96841 9.93062L6.49352 8.49835Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_1941_7757">
                    <rect x="1.5" y="1.5" width="15" height="15" rx="7.5" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

interface PasswordRequirementsProps {
    password: string
}

/**
 * Displays a list of password requirements, and indicates via a checkmark or X if the requirement is met
 */
const PasswordRequirements = ({ password } : PasswordRequirementsProps) : JSX.Element => {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            {requirements.map((req, idx) => (
                <span key={idx} className={classes.row}>
                    <span className="x-icon">
                        {req.condition.test(password) ? <CheckSVG /> : <XSVG />}
                    </span>
                    &nbsp;
                    <span><Typography variant="helperText" color="#6E7A82" display='inline'>{req.label}</Typography></span>
                </span>
            ))}
        </div>
    )
}

export default PasswordRequirements
